import React, { useCallback, useMemo } from 'react'
import {
  SelectProps
} from 'antd'

import {
  Option,
  Select
} from 'src/components/Select'
import { ConditionType } from 'src/types/transactions'
import { CONDITION_TYPES } from 'src/utils/constants'

interface ConditionTypeSelectProps extends SelectProps {
  disableOptions?: ConditionType[]
}

export default function ConditionTypeSelect(props: ConditionTypeSelectProps) {
  const { disableOptions, ...restProps } = props

  const isOptionDisable = useCallback((conditionType: ConditionType) => {
    if (disableOptions) {
      return disableOptions?.indexOf(conditionType) > -1
    }
    return false
  }, [])

  const renderFuelTypes = () => CONDITION_TYPES.map((conditionType: ConditionType) => (
    <Option
      disabled={isOptionDisable(conditionType)}
      key={`conditionType.${conditionType}`}
      value={conditionType}
    >
      {conditionType}
    </Option>
  ))
  return (
    <Select {...restProps}>
      {renderFuelTypes()}
    </Select>
  )
}

ConditionTypeSelect.defaultProps = {
  disableOptions: ['New', 'Rebuilt']
}
