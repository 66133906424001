import React from 'react'
import type { DatePickerProps } from 'antd'
import { DatePicker as ADatePicker } from 'antd'
import classNames from 'classnames'

import './styles.scss'

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY']

export default function DatePicker(props: DatePickerProps) {
  const { className, ...restProps } = props
  return (
    <ADatePicker
      className={classNames('kuaay-datepicker', className)}
      format={'MM/DD/YYYY'}
      {...restProps}
    />
  )
}
