import React from 'react'
import {
  Input as AntInput, InputProps
} from 'antd'
import classNames from 'classnames'

import './TextArea.scss'

export interface TextAreaProps {
  value?: string
  className?: string
  placeholder?: string
  rows: number
  disabled?: boolean
  onChange?: (val: React.ChangeEvent<HTMLTextAreaElement>) => void
  onInput?: (val: React.FormEvent) => void
  onBlur?: (val: React.FormEvent) => void
}

export default function TextArea(props: TextAreaProps) {
  const {
    className, placeholder, rows, disabled, onChange, onInput, onBlur, value
  } = props

  return (
    <AntInput.TextArea
      value={value}
      className={
        classNames('kuaay-textarea', className)
      }
      placeholder={placeholder}
      rows={rows}
      disabled={disabled}
      onChange={onChange}
      onInput={onInput}
      onBlur={onBlur}
    />
  )
}
