import { EnumNewTransactionParams } from 'src/router/types'
import { AppActions } from 'src/store/rootActions'
import { CreateTxnPaymentTransfer } from 'src/types/paymentTransfer'
import {
  EnumMailingAddress, PersonalInfo, TxnShipment, VehicleInfo
} from 'src/types/transactions'
import { STORAGE_KEY } from 'src/utils/constants'
import { getStorageItemAsObject } from 'src/utils/localStorage'
import { EnumShareStep, EnumUserType } from 'src/utils/types'

export type TransactionParams = {
  step: EnumNewTransactionParams
  userType: EnumUserType
  hasBeenShared?: boolean
  shareStep?: EnumShareStep | null
  shareTransactionId?: string | null
}

type CreateTransactionState = {
  transactionId: string
  complete: boolean
  mainUser: PersonalInfo
  secondaryUser: PersonalInfo
  vehicle: VehicleInfo
  paymentTransfer: CreateTxnPaymentTransfer
  shipment: TxnShipment
  mailingType: EnumMailingAddress
  isInternationalAddress?: boolean
  // This is the Base64 data (with prefix!)
  pdfDataBase64?: string
  secondaryUserPhone?: string
  secondaryUserEmail?: string
  lastParams?: TransactionParams
  documentEnabled: boolean
  askBuyerPayForTheFees: boolean
}

const initialUser = {
  firstName: '',
  middleName: '',
  lastName: '',
  role: undefined,
  license: ''
}

const defaultCreateTransactionState: CreateTransactionState = {
  transactionId: '',
  complete: false,
  mainUser: initialUser,
  secondaryUser: initialUser,
  mailingType: EnumMailingAddress.SameMailing,
  isInternationalAddress: false,
  vehicle: {
    registrationState: null,
    vin: '',
    make: '',
    model: '',
    vehicleLengthUnit: 'inches',
    odometerUnit: 'miles',
    emissionExtension: false,
    condition: 'Used',
    year: null
  },
  paymentTransfer: {
    enabled: true,
    protectionEnabled: false,
    buyer: {
      knownIssuesAgreement: {
        isAgreed: false,
        rejectReasons: ''
      }
    },
    seller: {
      knownIssuesDisclosure: ''
    }
  },
  shipment: {
    enabled: true
  },
  documentEnabled: true,
  askBuyerPayForTheFees: false,
  lastParams: undefined
}

const loadedTransaction: CreateTransactionState = getStorageItemAsObject<CreateTransactionState>(
  STORAGE_KEY.KUAAY_CREATE_TRANSACTION,
  defaultCreateTransactionState
)

if (loadedTransaction.vehicle.purchaseDate) {
  loadedTransaction.vehicle.purchaseDate = new Date(loadedTransaction.vehicle.purchaseDate)
}

if (loadedTransaction.vehicle.vtExpirationDate) {
  loadedTransaction.vehicle.vtExpirationDate = new Date(loadedTransaction.vehicle.vtExpirationDate)
}

if (loadedTransaction.mainUser.dateOfBirth) {
  loadedTransaction.mainUser.dateOfBirth = new Date(loadedTransaction.mainUser.dateOfBirth)
}

if (loadedTransaction.secondaryUser.dateOfBirth) {
  loadedTransaction.secondaryUser.dateOfBirth = new Date(loadedTransaction.secondaryUser.dateOfBirth)
}

const initialCreateTransactionState: CreateTransactionState = { ...defaultCreateTransactionState, ...loadedTransaction }

const createTransactionReducer = (
  prevState: CreateTransactionState,
  action: AppActions
): CreateTransactionState => {
  switch (action.type) {
  case 'RESET_TRANSACTION_STATE':
    return defaultCreateTransactionState
  case 'UPDATE_LAST_TRANSACTION_PARAMS':
    return {
      ...prevState,
      lastParams: action.lastParams
    }
  case 'UPDATE_IS_INTERNATIONAL_ADDRESS':
    return {
      ...prevState,
      isInternationalAddress: action.isInternationalAddress
    }
  case 'SET_MAIN_USER':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        ...action.mainUser
      }
    }
  case 'SET_TRANSACTION_VEHICLE_INFO':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        ...action.vehicle
      }
    }
  case 'SET_TRANSACTION_SHIPMENT':
    return {
      ...prevState,
      shipment: {
        ...prevState.shipment,
        ...action.shipment
      }
    }
  case 'RESET_TRANSACTION_STATE_MANUALLY':
    return {
      transactionId: action.transactionId,
      complete: false,
      mailingType: EnumMailingAddress.SameMailing,
      mainUser: action.mainUser,
      secondaryUser: action.secondaryUser,
      vehicle: action.vehicle,
      paymentTransfer: action.paymentTransfer,
      shipment: action.shipment,
      pdfDataBase64: action?.pdfDataBase64,
      secondaryUserPhone: action?.secondaryUserPhone,
      secondaryUserEmail: action?.secondaryUserEmail,
      documentEnabled: action.documentEnabled,
      askBuyerPayForTheFees: action.askBuyerPayForTheFees
    }
  case 'UPDATE_TRANSACTION_ID':
    return {
      ...prevState,
      transactionId: action.transactionId
    }
  case 'UPDATE_TRANSACTION_COMPLETED':
    return {
      ...prevState,
      complete: action.complete
    }
  case 'UPDATE_MAILING_TYPE':
    return {
      ...prevState,
      mailingType: action.mailingType
    }
  case 'UPDATE_MAIN_USER_IS_BUSINESS':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        isBusiness: action.isBusiness
      }
    }
  case 'UPDATE_MAIN_USER_DIFFERENT_MAILING_ADDRESS':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        diffMailingAddress: action.diffMailingAddress
      }
    }
  case 'UPDATE_MAIN_USER_FIRST_NAME':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        firstName: action.firstName
      }
    }
  case 'UPDATE_MAIN_USER_MIDDLE_NAME':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        middleName: action.middleName
      }
    }
  case 'UPDATE_MAIN_USER_LAST_NAME':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        lastName: action.lastName
      }
    }
  case 'UPDATE_MAIN_USER_ROLE':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        role: action.role
      }
    }
  case 'UPDATE_MAIN_USER_LICENSE':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        license: action.license
      }
    }
  case 'UPDATE_MAIN_USER_ADDRESS':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        address: action.address
      }
    }
  case 'UPDATE_MAIN_USER_ADDRESS2':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        address2: action.address2
      }
    }
  case 'UPDATE_MAIN_USER_STATE':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        state: action.state
      }
    }
  case 'UPDATE_MAIN_USER_ZIP':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        zip: action.zip
      }
    }
  case 'UPDATE_MAIN_USER_CITY':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        city: action.city
      }
    }
  case 'UPDATE_MAIN_USER_AUTHORIZED_REP_TITLE':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        authorizedRepTitle: action.authorizedRepTitle
      }
    }
  case 'UPDATE_MAIN_USER_ADDRESS_MAILING':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        addressMailing: action.addressMailing
      }
    }
  case 'UPDATE_MAIN_USER_ADDRESS_MAILING2':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        addressMailing2: action.addressMailing2
      }
    }
  case 'UPDATE_MAIN_USER_STATE_MAILING':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        stateMailing: action.stateMailing
      }
    }
  case 'UPDATE_MAIN_USER_ZIP_MAILING':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        zipMailing: action.zipMailing
      }
    }
  case 'UPDATE_MAIN_USER_CITY_MAILING':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        cityMailing: action.cityMailing
      }
    }
  case 'UPDATE_MAIN_USER_PHONE':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        phone: action.phone
      }
    }
  case 'UPDATE_MAIN_USER_EMAIL':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        email: action.email
      }
    }
  case 'UPDATE_MAIN_USER_SIGNATURE':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        signature: action.signature
      }
    }
  case 'UPDATE_MAIN_USER_SEX':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        sex: action.sex
      }
    }
  case 'UPDATE_MAIN_USER_DATE_OF_BIRTH':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        dateOfBirth: action.dateOfBirth
      }
    }
  case 'UPDATE_SECONDARY_USER_IS_BUSINESS':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        isBusiness: action.isBusiness
      }
    }
  case 'UPDATE_SECONDARY_USER_DIFFERENT_MAILING_ADDRESS':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        diffMailingAddress: action.diffMailingAddress
      }
    }
  case 'UPDATE_SECONDARY_USER_FIRST_NAME':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        firstName: action.firstName
      }
    }
  case 'UPDATE_SECONDARY_USER_MIDDLE_NAME':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        middleName: action.middleName
      }
    }
  case 'UPDATE_SECONDARY_USER_LAST_NAME':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        lastName: action.lastName
      }
    }
  case 'UPDATE_SECONDARY_USER_ROLE':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        role: action.role
      }
    }
  case 'UPDATE_SECONDARY_USER_LICENSE':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        license: action.license
      }
    }
  case 'UPDATE_SECONDARY_USER_ADDRESS':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        address: action.address
      }
    }
  case 'UPDATE_SECONDARY_USER_ADDRESS2':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        address2: action.address2
      }
    }
  case 'UPDATE_SECONDARY_USER_STATE':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        state: action.state
      }
    }
  case 'UPDATE_SECONDARY_USER_ZIP':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        zip: action.zip
      }
    }
  case 'UPDATE_SECONDARY_USER_CITY':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        city: action.city
      }
    }
  case 'UPDATE_SECONDARY_USER_AUTHORIZED_REP_TITLE':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        authorizedRepTitle: action.authorizedRepTitle
      }
    }
  case 'UPDATE_SECONDARY_USER_ADDRESS_MAILING':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        addressMailing: action.addressMailing
      }
    }
  case 'UPDATE_SECONDARY_USER_ADDRESS_MAILING2':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        addressMailing2: action.addressMailing2
      }
    }
  case 'UPDATE_SECONDARY_USER_STATE_MAILING':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        stateMailing: action.stateMailing
      }
    }
  case 'UPDATE_SECONDARY_USER_ZIP_MAILING':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        zipMailing: action.zipMailing
      }
    }
  case 'UPDATE_SECONDARY_USER_CITY_MAILING':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        cityMailing: action.cityMailing
      }
    }
  case 'UPDATE_SECONDARY_USER_PHONE':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        phone: action.phone
      }
    }
  case 'UPDATE_SECONDARY_USER_EMAIL':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        email: action.email
      }
    }
  case 'UPDATE_SECONDARY_USER_SIGNATURE':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        signature: action.signature
      }
    }
  case 'UPDATE_SECONDARY_USER_SEX':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        sex: action.sex
      }
    }
  case 'UPDATE_SECONDARY_USER_DATE_OF_BIRTH':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        dateOfBirth: action.dateOfBirth
      }
    }
  case 'UPDATE_VEHICLE_YEAR':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        year: action.year
      }
    }
  case 'UPDATE_VEHICLE_SALE_PRICE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        salePrice: action.salePrice
      }
    }
  case 'UPDATE_VEHICLE_NADA_VALUE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        nadaValue: action.nadaValue
      }
    }
  case 'UPDATE_VEHICLE_TYPE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        type: action.vehicleType
      }
    }
  case 'UPDATE_VEHICLE_REGISTRATION_STATE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        registrationState: action.registrationState
      }
    }
  case 'UPDATE_VEHICLE_ORIGINALLY_INTERNATIONAL_STATE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        originallyInternational: action.originallyInternational
      }
    }
  case 'UPDATE_VEHICLE_VIN':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        vin: action.vin
      }
    }
  case 'UPDATE_VEHICLE_MAKE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        make: action.make
      }
    }
  case 'UPDATE_VEHICLE_MODEL':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        model: action.model
      }
    }
  case 'UPDATE_VEHICLE_BODY_TYPE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        bodyType: action.bodyType
      }
    }
  case 'UPDATE_VEHICLE_NUM_AXLES':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        numAxles: action.numAxles
      }
    }
  case 'UPDATE_VEHICLE_BRAKE_TYPE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        brakeType: action.brakeType
      }
    }
  case 'UPDATE_VEHICLE_FUEL_TYPE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        fuelType: action.fuelType
      }
    }
  case 'UPDATE_VEHICLE_CONDITION':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        condition: action.condition
      }
    }
  case 'UPDATE_VEHICLE_SALVAGE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        salvage: action.salvage
      }
    }
  case 'UPDATE_VEHICLE_EMISSION_EXTENSION':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        emissionExtension: action.emissionExtension
      }
    }
  case 'UPDATE_VEHICLE_NUM_WHEELS':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        numWheels: action.numWheels
      }
    }
  case 'UPDATE_VEHICLE_CUBIC_CENTIMETERS':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        cubicCentimeters: action.cubicCentimeters
      }
    }
  case 'UPDATE_VEHICLE_COLOR':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        color: action.color
      }
    }
  case 'UPDATE_VEHICLE_LENGTH_UNIT':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        vehicleLengthUnit: action.vehicleLengthUnit
      }
    }
  case 'UPDATE_VEHICLE_LENGTH':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        vehicleLength: action.vehicleLength
      }
    }
  case 'UPDATE_VEHICLE_ODOMETER_UNIT':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        odometerUnit: action.odometerUnit
      }
    }
  case 'UPDATE_VEHICLE_ODOMETER_READING':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        odometerReading: action.odometerReading
      }
    }
  case 'UPDATE_VEHICLE_ODOMETER_DISCLOSURE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        odometerDisclosure: action.odometerDisclosure
      }
    }
  case 'UPDATE_VEHICLE_PURCHASE_DATE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        purchaseDate: action.purchaseDate
      }
    }
  case 'UPDATE_VEHICLE_NUM_CYLINDERS':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        numCylinders: action.numCylinders
      }
    }
  case 'UPDATE_VEHICLE_NUM_REG_YEARS':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        numRegYears: action.numRegYears
      }
    }
  case 'UPDATE_VEHICLE_VT_REG_NUMBER':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        vtRegNumber: action.vtRegNumber
      }
    }
  case 'UPDATE_VEHICLE_VT_TITLE_NUMBER':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        vtTitleNumber: action.vtTitleNumber
      }
    }
  case 'UPDATE_VEHICLE_VT_EXPIRATION_DATE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        vtExpirationDate: action.vtExpirationDate
      }
    }
  case 'UPDATE_VEHICLE_VT_PLATE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        vtPlate: action.vtPlate
      }
    }
  case 'UPDATE_CREATE_DOCUMENT_ENABLED':
    return {
      ...prevState,
      documentEnabled: action.enabled
    }
  case 'UPDATE_ASK_BUYER_PAY_FOR_THE_FEES':
    return {
      ...prevState,
      askBuyerPayForTheFees: action.askBuyerPayForTheFees
    }
  case 'UPDATE_PAYMENT_TRANSFER_ENABLED':
    return {
      ...prevState,
      paymentTransfer: {
        ...prevState.paymentTransfer,
        enabled: action.enabled
      }
    }
  case 'UPDATE_PAYMENT_TRANSFER_PROTECTION_ENABLED':
    return {
      ...prevState,
      paymentTransfer: {
        ...prevState.paymentTransfer,
        protectionEnabled: action.protectionEnabled
      }
    }
  case 'UPDATE_KNOWN_ISSUES_DISCLOSURE':
    return {
      ...prevState,
      paymentTransfer: {
        ...prevState.paymentTransfer,
        seller: {
          knownIssuesDisclosure: action.disclosure
        }
      }
    }
  case 'UPDATE_KNOWN_ISSUES_AGREEMENT':
    return {
      ...prevState,
      paymentTransfer: {
        ...prevState.paymentTransfer,
        buyer: {
          knownIssuesAgreement: {
            isAgreed: action.isAgreed,
            rejectReasons: action.rejectReasons
          }
        }
      }
    }
  case 'UPDATE_SHIPMENT_ENABLED':
    return {
      ...prevState,
      shipment: {
        ...prevState.shipment,
        enabled: action.enabled
      }
    }
  // case 'UPDATE_SELF_TRANSFER_PREVIOUS_REG_OR_TAXES':
  //   return {
  //     ...prevState,
  //     vehicle: {
  //       ...prevState.vehicle,
  //       prevRegOrTaxes: action.prevRegOrTaxes
  //     }
  //   }
  case 'UPDATE_PDF_DATA':
    return {
      ...prevState,
      pdfDataBase64: action.pdfDataBase64
    }
  case 'UPDATE_MAIN_USER_DATA_FROM_LICENSE':
    return {
      ...prevState,
      mainUser: {
        ...prevState.mainUser,
        firstName: action.firstName,
        middleName: action.middleName,
        lastName: action.lastName,
        city: action.city,
        state: action.state,
        zip: action.zip,
        license: action.license,
        address: action.address,
        address2: action.address2,
        sex: action.sex, // || prevState.secondaryUser.sex,
        dateOfBirth: action.dateOfBirth // || prevState.secondaryUser.dateOfBirth,
      }
    }
  case 'UPDATE_SECONDARY_USER_DATA_FROM_LICENSE':
    return {
      ...prevState,
      secondaryUser: {
        ...prevState.secondaryUser,
        firstName: action.firstName,
        middleName: action.middleName,
        lastName: action.lastName,
        city: action.city,
        state: action.state,
        zip: action.zip,
        license: action.license,
        address: action.address,
        address2: action.address2,
        sex: action.sex, // || prevState.secondaryUser.sex,
        dateOfBirth: action.dateOfBirth // || prevState.secondaryUser.dateOfBirth,
      }
    }
  case 'UPDATE_VEHICLE_DATA_FROM_LICENSE':
    return {
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        make: action.make,
        model: action.model,
        year: action.year,
        fuelType: action.fuelType,
        numCylinders: action.numCylinders,
        bodyType: action.bodyType
      }
    }
  default:
    return prevState
  }
}

export type { CreateTransactionState }

export {
  createTransactionReducer,
  initialCreateTransactionState,
  defaultCreateTransactionState
}
