import React from 'react'
import { Typography } from 'antd'

import { Spacer } from 'src/components/Spacer'

import './styles.scss'

const { Text } = Typography

export type SellerProviderProps = {
  vehicleAge: number;
  registeredIntl: boolean;
  originallyIntl: boolean;
}

export default function SellerProvideInfo({ vehicleAge, originallyIntl, registeredIntl }: SellerProviderProps) {
  const
    yearForOdometerDisclosure : number = 2012
  const currentYear : number = new Date().getFullYear()
  const
    requireSellerProviderInfo : boolean = (currentYear - 14 < vehicleAge)
  const requireOdometerDisclosure : boolean = (vehicleAge >= yearForOdometerDisclosure)

  return (
    <>
      {
        requireSellerProviderInfo && (
          <>
            <div className="info-section">
              <div className="info-section__title">Certificate of Title</div>
              <div className="info-section__text">
                {'\n'}
                {' '}
                Seller will provide an original previous document assigned to you by all
                parties on the title and bills of sale as necessary to show complete chain of ownership.
              </div>
            </div>
            {
              requireOdometerDisclosure && (
                <>
                  <Spacer space={36} />
                  <div className="info-section">
                    <div className="info-section__title">Odometer Disclosure Statement</div>
                    <div className="info-section__text">
                      {'\n'}
                      {' '}
                      { "Required if the vehicle's model year is on or newer than 2012" }
                    </div>
                  </div>
                </>
              )
            }
          </>
        )
      }
      {
        registeredIntl && (
          <div className="info-section">
            <div className="info-section__title">CBP Import Document</div>
            <div className="info-section__text">
              {'\n'}
              Seller will provide a color copy or original of EPA 3520-1 received from Customs and
              Border Protection (CBP) at the time of import.
            </div>
          </div>
        )
      }
      {
        originallyIntl && !registeredIntl && (
          <div className="info-section">
            <div className="info-section__title">Previous Registration</div>
            <div className="info-section__text">
              {'\n'}
              {' '}
              Seller will provide a prior state registration for the vehicle.
            </div>
          </div>
        )
      }
    </>
  )
}
