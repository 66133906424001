import { Sex } from 'src/types/transactions'

import { US_DRIVERS_LICENSE_FIELDS } from './constants'

// Get driver license abbreviation-content map from raw txt
// See: https://github.com/yushulx/javascript-driver-license/blob/master/driverlicense.js
export type LicenseBarcodeInfo = {
  [key: string]: { description: string; content: string, name: string };
};
export const parseDriversLicense = (rawText: string) => {
  const lines = rawText.split('\n')
  const abbreviations = Object.keys(US_DRIVERS_LICENSE_FIELDS)
  const map: LicenseBarcodeInfo = {}
  lines.forEach((line, i) => {
    let abbreviation
    let content
    if (i === 1) {
      // Snag the license number tucked away in the ANSI code
      abbreviation = 'DAQ'
      content = line.substring(line.indexOf(abbreviation) + 3)
    } else {
      // Split the string into the abbreviation and then the actual content
      abbreviation = line.substring(0, 3)
      content = line.substring(3).trim()
    }
    if (abbreviations.includes(abbreviation)) {
      map[abbreviation] = {
        description: US_DRIVERS_LICENSE_FIELDS[abbreviation]?.description,
        content,
        name: US_DRIVERS_LICENSE_FIELDS[abbreviation]?.name
      }
    }
  })
  return map
}

export const licenseSexParser = (strSex: string): Sex => {
  switch (strSex) {
  case '1':
    return 'Male'
  case '0':
    return 'Female'
  default:
    return undefined
  }
}

export const licenseDateOfBirthParser = (strDOB: string): Date | undefined => {
  // First check if the string start with 19 or 20 (so that it is in the YYYYMMDD format)
  if (strDOB.slice(0, 2) === '19' || strDOB.slice(0, 2) === '20') {
    try {
      const year = parseInt(strDOB.slice(0, 4), 10)
      const month = parseInt(strDOB.slice(4, 6), 10)
      const day = parseInt(strDOB.slice(6, 8), 10)
      const dob = new Date(`${month}/${day}/${year}`)
      if (Number.isNaN(dob.getTime())) {
        return undefined
      }
      return dob
    } catch (err) {
      return undefined
    }
  } else {
    // This should be in MMDDYYYY format
    try {
      const month = parseInt(strDOB.slice(0, 2), 10)
      const day = parseInt(strDOB.slice(2, 4), 10)
      const year = parseInt(strDOB.slice(4, 8), 10)
      const dob = new Date(`${month}/${day}/${year}`)
      if (Number.isNaN(dob.getTime())) {
        console.log('Failed to get date!')
      }
      return dob
    } catch (err) {
      return undefined
    }
  }
}
