import { EnumRoute } from './types'

export const HIDDEN_BOTTOM_NAV_PAGES = [
  EnumRoute.SignIn,
  EnumRoute.SignUp,
  EnumRoute.Settings,
  EnumRoute.PubShipmentQuote
]

export const HIDDEN_TOP_NAV_PAGES = [
  EnumRoute.PubShipmentQuote
]
