import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Typography } from 'antd'

import { EnumRoute } from 'src/router/types'

import { NoTransactionCardProps } from './types'

import './NoTransactionCard.scss'

export default function NoTransactionCard({ isPending }: NoTransactionCardProps) {
  const [isFocus, setIsFocus] = useState(false)
  const navigate = useNavigate()
  const description = useMemo(() => (isPending ? (
    <div>To start building your pending transactions</div>
  ) : (
    <div>To start building your history</div>
  )), [isPending])

  const handleMouseDown = () => {
    setIsFocus(true)
  }
  const handleMouseUp = () => {
    setIsFocus(false)
  }

  const handleClick = useCallback(() => {
    setTimeout(() => {
      if (navigate) {
        navigate(EnumRoute.NewTransaction)
      }
    }, 100)
  }, [navigate])

  return (
    <Card
      className="no-transaction-card"
      data-is-focus={isFocus}
      title="Create A Transaction"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onClick={handleClick}
    >
      <div>
        {description}
      </div>
    </Card>
  )
}
