import React from 'react'
import {
  SelectProps
} from 'antd'

import {
  Option,
  Select
} from 'src/components/Select'
import { US_STATES } from 'src/utils/constants'

type USStateProps = SelectProps

export default function USState(props: USStateProps) {
  const { placeholder } = props
  const renderUSState = () => [...US_STATES]
    .map((stateAndAbbrev) => (
      <Option key={`state.${stateAndAbbrev[0]}`} value={stateAndAbbrev[0]}>
        {stateAndAbbrev[0]}
      </Option>
    ))
  return (
    <Select {...props} placeholder={placeholder ?? 'State'}>
      { renderUSState() }
    </Select>
  )
}
