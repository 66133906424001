import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react'
import { DeleteOutlined, MoreOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
  Dropdown, MenuProps, Space
} from 'antd'

import { HeaderLayout } from 'src/components/HeaderLayout'
import { InfoModal } from 'src/components/Modal'
import { useNewShipment } from 'src/hooks/useNewShipment'
import { EnumNewShipmentStep } from 'src/router/types'
import { store } from 'src/store/store'
import { TransactionInfo } from 'src/types/transactions'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { EvenBus, EvenBusType } from 'src/utils/evenBus'

import { NewShipmentMainInfo } from './NewShipmentMainInfo'
import { NewShipmentOrderConfirm } from './NewShipmentOrderConfirm'
import { NewShipmentPayment } from './NewShipmentPayment'

import './NewShipment.scss'

export const NewShipment = () => {
  const {
    step, transactionId, goPrevStep, resetNewShipmentState, navigateToStep
  } = useNewShipment()
  const [isReady, setIsReady] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const globalStore = useContext(store)
  useEffect(() => {
    if (transactionId && !globalStore.state.createShipment.shipmentId) {
      authApiAxiosWithRefresh()
        .then((axiosInst) => axiosInst.get(
          `/transaction/retrieve/${transactionId}`
        ))
        .then((res) => {
          const transaction = res.data as TransactionInfo
          if (transaction?.transactionId && transaction?.vehicle && !transaction?.shipment?.shipmentId) {
            globalStore.dispatch({
              type: 'UPDATE_SHIPMENT_TRANSACTION',
              transaction: res.data as TransactionInfo
            })
            if (step === EnumNewShipmentStep.MainInfo) {
              globalStore.dispatch({
                type: 'PARSE_SHIPMENT_INFO_FROM_TRANSACTION',
                transaction: res.data as TransactionInfo
              })
            }
          }
          setIsReady(true)
        })
        .catch(() => {
          setIsReady(true)
        })
    } else {
      setIsReady(true)
    }
  }, [])

  const handleClickStartOver = () => {
    resetNewShipmentState()
    setTimeout(() => {
      EvenBus.dispatch(EvenBusType.RESET_NEW_SHIPMENT_STATE, null)
      navigateToStep({ step: EnumNewShipmentStep.MainInfo })
    }, 200)
  }

  const rightMenuProps = useMemo(() => {
    return {
      items: [
        {
          key: '1',
          label: (
            <div
              className="new-transaction__dropdown-menu-item"
              onClick={() => setShowInfoModal(true)}
            >
              <QuestionCircleOutlined className="new-transaction__dropdown-menu-item-icon" />
              <span className="new-transaction__dropdown-menu-item-text">Info</span>
            </div>
          )
        },
        {
          type: 'divider'
        },
        {
          key: '2',
          label: (
            <div
              className="new-transaction__dropdown-menu-item"
              onClick={handleClickStartOver}
            >
              <DeleteOutlined className="new-transaction__dropdown-menu-item-icon" />
              <span className="new-transaction__dropdown-menu-item-text">
                Start over
              </span>
            </div>
          )
        }
      ]
    } as MenuProps
  }, [])

  const renderRightItems = useCallback(() => (
    <Dropdown
      menu={rightMenuProps}
      trigger={['click']}
      placement="bottomRight"
    >
      <span onClick={(e) => e.preventDefault()}>
        <Space>
          <MoreOutlined />
        </Space>
      </span>
    </Dropdown>
  ), [step])

  const infoText = useMemo(() => {
    switch (step) {
    case EnumNewShipmentStep.Payment:
      return 'Checkout to get the shipping process started! 💪'
    case EnumNewShipmentStep.OrderConfirm:
      return 'Congrats on your purchase!\nYour shipping request is being processed.'
    default:
      return 'Please follow below steps:\n\n'
        + '1. Enter information in section "Main information and Quote" and get quote.\n'
        + '2. Enter additional information and continue.\n'
        + '   \n'
    }
  }, [step])

  const subtitle = useMemo(() => {
    switch (step) {
    case EnumNewShipmentStep.MainInfo:
      return 'Shipping Info and Quote'
    case EnumNewShipmentStep.Payment:
      return 'Payment'
    case EnumNewShipmentStep.OrderConfirm:
      return 'Order confirm'
    default:
      return ''
    }
  }, [step])

  const body = useMemo(() => {
    switch (step) {
    case EnumNewShipmentStep.Payment:
      return <NewShipmentPayment />
    case EnumNewShipmentStep.OrderConfirm:
      return <NewShipmentOrderConfirm />
    case EnumNewShipmentStep.MainInfo:
    default:
      return <NewShipmentMainInfo />
    }
  }, [step])

  return (
    <>
      <HeaderLayout
        hasGoBack
        title="New Shipping"
        subtitle={subtitle}
        rightItems={renderRightItems}
        onGoBack={goPrevStep}
      >
        { isReady && body }
      </HeaderLayout>
      <InfoModal
        text={infoText}
        open={showInfoModal}
        onClose={() => setShowInfoModal(false)}
      />
    </>
  )
}
