import React, { useContext, useMemo } from 'react'
import { Col, Row, Typography } from 'antd'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { INTERNATIONAL_CAR_REG } from 'src/utils/constants'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'

import { SellerProvideInfo } from './seller-provide-info'

import './styles.scss'

const { Title } = Typography

interface NewTransactionNeededInfoProps {
  userType: EnumUserType
}

export default function NewTransactionNeededInfo({ userType }: NewTransactionNeededInfoProps) {
  const { navigateToStep, shareTransactionId } = useNewTransaction()
  const globalStore = useContext(store)
  const { documentEnabled, paymentTransfer } = globalStore.state.createTransaction
  const { protectionEnabled } = paymentTransfer

  const { role: userRole } = transactionUserInfo(userType, globalStore)

  const handleClickContinue = () => {
    if (userType === EnumUserType.Main && userRole === EnumUserRole.Buyer) {
      navigateToStep({ step: EnumNewTransactionParams.SpecificVehicleInfo })
    } else {
      navigateToStep({ step: EnumNewTransactionParams.UserInformation })
    }
  }

  const pageTitle = useMemo(() => {
    if (userType === EnumUserType.Main) {
      return `Here is what you need from the ${
        userRole === EnumUserRole.Buyer ? EnumUserRole.Seller : EnumUserRole.Buyer
      }`
    }
    // SECONDARY
    return `Here is what you need to provide to${userRole === EnumUserRole.Buyer ? ' and get from' : ''} the ${
      userRole === EnumUserRole.Buyer ? EnumUserRole.Seller : EnumUserRole.Buyer
    }`
  }, [userType])

  const otherInfoMain = useMemo(() => (
    <div className="other-info">
      <p className="other-info__main-text font-weight-bold">
        Other documents/information depend on state, year, and vehicle type.
        Options are:
      </p>
      <ol className="other-info__check-list">
        <li>Vehicle’s title.</li>
        <li>Reading on the vehicle’s odometer.</li>
        <li>
          Vehicle Identification Number (VIN) number written
          underneath the windshield.
        </li>
        {
          userRole === EnumUserRole.Buyer && (
            <li>
              Some details from their
              { ' ' }
              { "driver's license or other photo ID." }
            </li>
          )
        }
      </ol>
    </div>
  ), [userRole])

  const otherInfoSecondary = useMemo(() => (
    <div className="other-info">
      <p className="other-info__main-text">
        Make sure to check all the vehicle information has been
        entered correctly.
      </p>
      <ol className="other-info__check-list">
        <li>
          { userRole === EnumUserRole.Buyer ? 'Provide s' : 'S' }
          { "ome details from your driver's license or other photo ID."}
        </li>
        {
          documentEnabled && (
            <li>
              {userRole === EnumUserRole.Buyer ? 'Provide a' : 'A'}
              {' '}
              confirmation of the odometer reading.
            </li>
          )
        }
        {
          protectionEnabled && userRole === EnumUserRole.Seller && (
            <li>
              A disclosure of known issues of your vehicle.
            </li>
          )
        }
        {
          protectionEnabled && userRole === EnumUserRole.Buyer && (
            <li>
              An agreement on known issues disclosure from the seller.
            </li>
          )
        }
        {
          documentEnabled && userRole === EnumUserRole.Buyer && globalStore.state.createTransaction.vehicle
            .registrationState === INTERNATIONAL_CAR_REG && (
            <li>
              {userRole === EnumUserRole.Buyer ? 'Get an ' : ''}
              EPA 3520-1 color copy or original initially administered Customs and
              Border Protection (CBP) at the time of import.
            </li>
          )
        }
      </ol>
    </div>
  ), [userRole])

  const otherInfo = useMemo(() => (userType === EnumUserType.Main ? otherInfoMain : otherInfoSecondary), [userType])

  return (
    <Row className="needed-info">
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        {
          userType === EnumUserType.Secondary && !shareTransactionId && (
            <div className="pass-the-device-note">

              At this time, please pass the device to the
              {' '}
              {userRole || 'other person'}

            </div>
          )
        }
        <Spacer space={8} />
        <Title className="needed-info__title" level={4}>{pageTitle}</Title>

        {
          documentEnabled && ((userRole === EnumUserRole.Seller && userType === EnumUserType.Secondary)
            || (userRole === EnumUserRole.Buyer && userType === EnumUserType.Main)) && (
            <SellerProvideInfo
              vehicleAge={globalStore.state.createTransaction.vehicle.year!}
              registeredIntl={
                globalStore.state.createTransaction.vehicle.registrationState === INTERNATIONAL_CAR_REG
              }
              originallyIntl={
                !!globalStore.state.createTransaction.vehicle.originallyInternational
              }
            />
          )
        }
        {otherInfo}
        <Spacer space={8} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="primary"
          onClick={handleClickContinue}
        >
          Continue
        </Button>
      </Col>
    </Row>
  )
}
