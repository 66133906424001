import React from 'react'
import {
  Select as ASelect,
  SelectProps
} from 'antd'
import classNames from 'classnames'

import './styles.scss'

export default function Select(props: SelectProps) {
  const { className, ...restProps } = props
  return (
    <ASelect className={classNames('kuaay-select', className)} {...restProps} />
  )
}
