import React, { useContext } from 'react'

import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { PersonalInfo } from 'src/types/transactions'
import { KycStatus } from 'src/types/users'
import { resolveAddressFromZip } from 'src/utils/locationHelper'
import { kycStatusOfSeller, transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'

import { EnumMessageType, useMessage } from '../../../hooks/useMessage'
import { US_STATES } from '../../../utils/constants'
import { authApiAxiosWithRefresh } from '../../../utils/customAxios'

export function useHandleSubmit(userType: EnumUserType) {
  const globalStore = useContext(store)
  const { documentEnabled, paymentTransfer, askBuyerPayForTheFees } = globalStore.state.createTransaction
  const { enabled: paymentTransferEnabled, protectionEnabled } = paymentTransfer

  const { role: userRole } = transactionUserInfo(userType, globalStore)
  const {
    navigateToStep, isBuyer, isSeller, hasBeenShared, shareTransactionId
  } = useNewTransaction()

  const { openMessage } = useMessage()
  const handleSubmitForm = async (data: PersonalInfo) => {
    const {
      license,
      firstName,
      middleName,
      lastName,
      zip,
      city,
      state,
      sex,
      phone,
      email,
      address,
      address2,
      /** Start Difference Mailing */
      addressMailing,
      addressMailing2,
      zipMailing,
      cityMailing,
      stateMailing
      /** End Difference Mailing */
    } = data

    const userInfo: PersonalInfo = {
      ...globalStore.state.createTransaction.mainUser,
      firstName,
      middleName,
      lastName,
      license,
      zip,
      city,
      state,
      sex,
      phone,
      email,
      address,
      address2,
      /** Start Difference Mailing */
      addressMailing,
      addressMailing2,
      zipMailing,
      cityMailing,
      stateMailing
      /** End Difference Mailing */
    }

    if (userType === EnumUserType.Main) {
      globalStore.dispatch({
        type: 'SET_MAIN_USER',
        mainUser: userInfo
      })
    } else {
      globalStore.dispatch({
        type: 'SET_SECONDARY_USER',
        secondaryUser: userInfo
      })
    }

    const kycStatus = await kycStatusOfSeller(globalStore)

    if (userType === EnumUserType.Main) {
      if (userRole === EnumUserRole.Seller && paymentTransferEnabled && kycStatus !== KycStatus.COMPLETED) {
        navigateToStep({ step: EnumNewTransactionParams.Kyc })
      } else if (!documentEnabled) {
        navigateToStep({ step: EnumNewTransactionParams.SpecificVehicleInfo })
      } else {
        navigateToStep({ step: EnumNewTransactionParams.MainVehicleInfo })
      }
    } else {
      if (paymentTransferEnabled && protectionEnabled) {
        navigateToStep({ step: EnumNewTransactionParams.PaymentProtectionDisclosure })
      } else if (isSeller && paymentTransferEnabled && kycStatus !== KycStatus.COMPLETED) {
        navigateToStep({ step: EnumNewTransactionParams.Kyc })
      } else if (!documentEnabled) {
        if (hasBeenShared) {
          if (isBuyer && askBuyerPayForTheFees) {
            navigateToStep({ step: EnumNewTransactionParams.Complete })
          } else {
            try {
              const res = await (
                await authApiAxiosWithRefresh()
              )
                .post('/transaction/share/continue', {
                  transactionInfo: globalStore.state.createTransaction,
                  shareTransactionId
                })

              navigateToStep({
                step: EnumNewTransactionParams.ShareConfirmation,
                userType,
                shareUrl: encodeURIComponent(res.data.shareUrl)
              })
            } catch (e) {
              openMessage(
                {
                  type: EnumMessageType.ERROR,
                  text1: 'There was an error while storing your transaction',
                  text2: 'Please try again soon.'
                }
              )
            }
          }
        } else {
          navigateToStep({ step: EnumNewTransactionParams.Complete })
        }
      } else if (userRole === EnumUserRole.Buyer) {
        navigateToStep({ step: EnumNewTransactionParams.SignatureApplication })
      } else {
        navigateToStep({ step: EnumNewTransactionParams.SpecificVehicleInfo })
      }
    }
  }

  const handleZipUpdate = async (text: string, form?: any, mailing: boolean = false) => {
    const zipResponse = await resolveAddressFromZip(text)
    if (zipResponse) {
      form.setFieldValue(!mailing ? 'zip' : 'zipMailing', zipResponse.zip)
      form.setFieldValue(!mailing ? 'city' : 'cityMailing', zipResponse.city)
      form.setFieldValue(!mailing ? 'state' : 'stateMailing', zipResponse.state)
      globalStore.dispatch({
        type:
          userType === EnumUserType.Main
            ? (!mailing ? 'UPDATE_MAIN_USER_ZIP' : 'UPDATE_MAIN_USER_ZIP_MAILING')
            : (!mailing ? 'UPDATE_SECONDARY_USER_ZIP' : 'UPDATE_SECONDARY_USER_ZIP_MAILING'),
        zip: zipResponse.zip,
        zipMailing: zipResponse.zip
      })
      globalStore.dispatch({
        type:
          userType === EnumUserType.Main
            ? (!mailing ? 'UPDATE_MAIN_USER_CITY' : 'UPDATE_MAIN_USER_CITY_MAILING')
            : (!mailing ? 'UPDATE_SECONDARY_USER_CITY' : 'UPDATE_SECONDARY_USER_CITY_MAILING'),
        city: zipResponse.city,
        cityMailing: zipResponse.city
      })
      globalStore.dispatch({
        type:
          userType === EnumUserType.Main
            ? (!mailing ? 'UPDATE_MAIN_USER_STATE' : 'UPDATE_MAIN_USER_STATE_MAILING')
            : (!mailing ? 'UPDATE_SECONDARY_USER_STATE' : 'UPDATE_SECONDARY_USER_STATE_MAILING'),
        state: zipResponse.state,
        stateMailing: zipResponse.state
      })
    }
  }

  return {
    handleSubmitForm,
    handleZipUpdate
  }
}
