import { Modal, Typography } from 'antd'

import { Button } from 'src/components/Button'

import './ConfirmModal.scss'

interface ConfirmModalProps {
  open: boolean
  width?: number
  cancelText?: string
  cancelColor?: string
  okText?: string
  title?: string
  onOk: () => void
  onCancel: () => void
  closable?: boolean
  children?: any
}

export default function ConfirmModal({
  open, width, title, okText, cancelText, cancelColor, onOk, onCancel, closable, children
}: ConfirmModalProps) {
  return (
    <Modal
      className="confirm-modal"
      centered
      width={width ?? 400}
      footer={null}
      open={open}
      closable={closable ?? false}
      onCancel={onCancel}
    >
      <div className="confirm-modal__content">
        {title && (
          <Typography.Title
            level={5}
            className="confirm-modal__title"
          >
            {title}
          </Typography.Title>
        )}
        <div className="confirm-modal__children">
          {children}
        </div>
        <div className="confirm-modal__actions">
          <Button
            className="confirm-modal__cancel-btn"
            type="primary"
            color={cancelColor ?? 'secondary'}
            size="small"
            onClick={onCancel}
          >
            { cancelText ?? 'Cancel' }
          </Button>
          <Button
            className="confirm-modal__ok-btn"
            type="primary"
            color="primary"
            size="small"
            onClick={onOk}
          >
            { okText ?? 'Ok' }
          </Button>
        </div>
      </div>
    </Modal>
  )
}
