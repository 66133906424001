import React from 'react'
import { Switch as AntSwitch } from 'antd'
import classNames from 'classnames'

import { useTheme } from 'src/hooks/useTheme'

import { SwitchProps } from './Switch.types'

import './switch.scss'

function Switch({ className, ...rest }: SwitchProps) {
  const { theme } = useTheme()

  return (
    <AntSwitch className={classNames('kuaay-switch', theme, className)} {...rest} />
  )
}

export { Switch }
