import {
  useContext, useMemo
} from 'react'
import {
  signInAnonymously, signOut as firebaseSignOut
} from 'firebase/auth'

import { getFirebaseAuth } from 'src/firebase/firebase'
import { store } from 'src/store/store'

const useAuthen = () => {
  const globalStore = useContext(store)
  const { auth } = globalStore.state

  const currentUser = useMemo(() => auth.user, [auth.user])
  const isAuthenLoaded = useMemo(() => !auth.isLoading && !!currentUser, [auth.isLoading, currentUser])
  const refreshToken = useMemo(() => auth.userRefreshToken, [auth.userRefreshToken])
  const token = useMemo(() => auth.userToken, [auth.userToken])

  const email = useMemo(() => currentUser?.email, [currentUser])
  const isAnonymous = useMemo(
    () => !currentUser || currentUser?.isAnonymous === true,
    [currentUser]
  )

  const signOut = async () => {
    await firebaseSignOut(getFirebaseAuth())
    await anonymousSignIn()
  }

  const reloadAuth = () => {
    const user = getFirebaseAuth().currentUser
    if (user) {
      globalStore.dispatch(
        {
          type: 'SIGN_IN',
          user,
          token: user.getIdToken(),
          refreshToken: user.refreshToken,
          isAnonymous: user.isAnonymous
        }
      )
    }
  }

  const anonymousSignIn = async () => {
    try {
      const firebaseAuth = getFirebaseAuth()
      const { user } = await signInAnonymously(firebaseAuth)
      globalStore.dispatch(
        {
          type: 'SIGN_OUT',
          user,
          token: user.getIdToken(),
          refreshToken: user.refreshToken,
          isAnonymous: user.isAnonymous
        }
      )
    } catch (err) {
      // skip
      globalStore.dispatch(
        {
          type: 'SIGN_OUT',
          user: null,
          anonToken: null,
          anonRefreshToken: null
        }
      )
    }

    // Now reset the previous transactions
    globalStore.dispatch({
      type: 'RESET_PREVIOUS_TRANSACTIONS_STATE'
    })
    // Now reset the pending transactions
    globalStore.dispatch({
      type: 'RESET_PENDING_TRANSACTIONS_STATE'
    })
    // Now reset the create transaction info
    globalStore.dispatch({
      type: 'RESET_TRANSACTION_STATE'
    })
    // Now reset the create shipment info
    globalStore.dispatch({
      type: 'RESET_CREATE_SHIPMENT_STATE'
    })
    // Now reset the prev shipments info
    globalStore.dispatch({
      type: 'RESET_SHIPMENTS_STATE'
    })
  }

  return {
    isAuthenLoaded,
    currentUser,
    isAnonymous,
    email,
    token,
    refreshToken,
    signOut,
    anonymousSignIn,
    reloadAuth
  }
}

export { useAuthen }
