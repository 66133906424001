import secureLocalStorage from 'react-secure-storage'

import { AppActions } from 'src/store/rootActions'
import { STORAGE_KEY } from 'src/utils/constants'
import { getStorageItemAsObject } from 'src/utils/localStorage'
import { EnumTheme } from 'src/utils/types'

type UserSettings = {
  theme: EnumTheme;
};
type UserState = {
  name: string;
  email: string;
  phone?: string;
  settings: UserSettings;
};

const initialUserState: UserState = getStorageItemAsObject<UserState>(
  STORAGE_KEY.KUAAY_USER_STATE,
  {
    name: '',
    email: '',
    settings: {
      theme: EnumTheme.Dark
    }
  }
)

const userReducer = (prevState: UserState, action: AppActions) => {
  let state: UserState = prevState
  switch (action.type) {
  case 'UPDATE_NAME':
    state = {
      ...prevState,
      name: action.name
    }
    break
  case 'UPDATE_PHONE':
    state = {
      ...prevState,
      phone: action.phone
    }
    break
  case 'UPDATE_THEME':
    state = {
      ...prevState,
      settings: {
        theme: action.theme
      }
    }
    break
  default:
    return prevState
  }
  secureLocalStorage.setItem(STORAGE_KEY.KUAAY_USER_STATE, state)
  return state
}
export type { UserState }
export { userReducer, initialUserState }
