import { useEffect, useMemo, useState } from 'react'

export const useDisplay = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const isPhoneDevice = useMemo(() => windowWidth < 768, [windowWidth])
  const isMobileDevice = useMemo(() => windowWidth < 1024, [windowWidth])
  const isDesktopOrUpper = useMemo(() => windowWidth >= 1024, [windowWidth])
  const formHeaderHeight = useMemo(() => (isMobileDevice ? 56 : 50), [isMobileDevice])
  const topBarHeight = useMemo(() => (isMobileDevice ? 0 : 116), [isMobileDevice])
  const bottomNavHeight = useMemo(() => (isMobileDevice ? 80 : 0), [isMobileDevice])
  const minFormHeight = useMemo(
    () => windowHeight - topBarHeight - formHeaderHeight - bottomNavHeight - 46,
    [windowHeight, topBarHeight, formHeaderHeight, bottomNavHeight]
  )

  const smallModalWidth = useMemo(() => (
    isPhoneDevice
      ? windowWidth - 48
      : 400
  ), [isMobileDevice, windowWidth])

  const largeModalWidth = useMemo(() => (
    isPhoneDevice
      ? windowWidth - 48
      : 700
  ), [isMobileDevice, windowWidth])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const onResize = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }

  return {
    windowWidth,
    windowHeight,
    isPhoneDevice,
    isMobileDevice,
    isDesktopOrUpper,
    minFormHeight,
    smallModalWidth,
    largeModalWidth
  }
}
