import React, {
  ChangeEvent, useContext, useEffect, useMemo
} from 'react'
import {
  Col, Form, FormInstance, Row
} from 'antd'
import dayjs from 'dayjs'

import { DatePicker } from 'src/components/DatePicker'
import { Input } from 'src/components/Input'
import { Option, Select } from 'src/components/Select'
import { Spacer } from 'src/components/Spacer'
import { store } from 'src/store/store'
import {
  EMAIL_REGEX, PHONE_NUMBER_REGEX, SEXES
} from 'src/utils/constants'
import { formatPhoneNumber } from 'src/utils/phoneHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'
import { EntityType } from 'src/views/new-transaction/types'

function getDateFrom20YearsAgo() {
  // 20 years ago
  return new Date(
    new Date().getFullYear() - 20,
    new Date().getMonth(),
    new Date().getDate()
  )
}

function getDateStringFrom20YearsAgo() {
  const date = getDateFrom20YearsAgo()
  return date.toLocaleDateString('en-GB')
}

interface ContactMailingProps {
  entityType: EntityType
  userRole?: EnumUserRole
  userType: EnumUserType
  form: FormInstance
}

export default function ContactMailing(props: ContactMailingProps) {
  const {
    entityType, userRole, userType, form
  } = props
  const globalStore = useContext(store)

  const {
    mainUser,
    secondaryUser
  } = globalStore.state.createTransaction

  useEffect(() => {
    if (userType === EnumUserType.Main) {
      form.setFieldValue('email', mainUser.email)
    }
  }, [mainUser.email, userType])

  const renderSex = () => SEXES.map((sex) => (
    <Option key={`sex.${sex}`} value={sex}>{sex}</Option>
  ))

  const initDateOfBirthValue = useMemo(() => {
    let defaultDate: string | null = null
    if (userType === EnumUserType.Main && mainUser.dateOfBirth) {
      defaultDate = mainUser.dateOfBirth?.toLocaleDateString('en-GB')
    } else if (userType === EnumUserType.Secondary && secondaryUser.dateOfBirth) {
      defaultDate = secondaryUser.dateOfBirth?.toLocaleDateString('en-GB')
    }
    return defaultDate ? dayjs(defaultDate, 'DD/MM/YYYY') : null
  }, [userType])

  const initialSexValue = useMemo(() => {
    if (userType === EnumUserType.Main) {
      return mainUser.sex
    }
    return secondaryUser.sex
  }, [userType])

  const reformatPhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const rawText = e.target.value
    if (!!rawText) {
      const formattedPhoneNumber = formatPhoneNumber(rawText)
      if (!!formattedPhoneNumber) {
        form.setFieldValue('phone', formattedPhoneNumber)
        form.validateFields(['phone'])
      }
      globalStore.dispatch({
        type:
          userType === EnumUserType.Main
            ? 'UPDATE_MAIN_USER_PHONE'
            : 'UPDATE_SECONDARY_USER_PHONE',
        phone: formattedPhoneNumber || rawText
      })
    }
  }

  const dateOfBirthCustomValidationRule = useMemo(() => ({
    validator(_: any, value: any) {
      if (userType === EnumUserType.Main
          && mainUser.dateOfBirth) {
        return Promise.resolve()
      }
      if (userType === EnumUserType.Secondary
          && secondaryUser.dateOfBirth) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Date Of Birth is required'))
    }
  }), [userType, mainUser.dateOfBirth, secondaryUser.dateOfBirth])

  return (
    <>
      {
        entityType === EntityType.Individual && userRole === EnumUserRole.Buyer && (
          <>
            <Row>
              <Col xs={11}>
                <Form.Item
                  name="sex"
                  initialValue={initialSexValue}
                  shouldUpdate={(prevValues, curValues) => prevValues.sex !== curValues.sex}
                  rules={[{ required: true, message: 'Sex is required' }]}
                >
                  <Select
                    placeholder="Sex"
                    onChange={(data) => {
                      globalStore.dispatch({
                        type: userType === EnumUserType.Main
                          ? 'UPDATE_MAIN_USER_SEX'
                          : 'UPDATE_SECONDARY_USER_SEX',
                        sex: data
                      })
                    }}
                  >
                    {renderSex()}
                  </Select>
                </Form.Item>
              </Col>
              <Col offset={1} xs={12}>
                <Form.Item
                  name="dateOfBirth"
                  rules={[
                    { required: true, message: 'Date Of Birth is required' }
                  ]}
                  initialValue={initDateOfBirthValue}
                  shouldUpdate={(
                    prevValues,
                    curValues
                  ) => prevValues.dateOfBirth !== curValues.dateOfBirth}
                >
                  <DatePicker
                    onChange={(d) => {
                      globalStore.dispatch({
                        type:
                      userType === EnumUserType.Main
                        ? 'UPDATE_MAIN_USER_DATE_OF_BIRTH'
                        : 'UPDATE_SECONDARY_USER_DATE_OF_BIRTH',
                        dateOfBirth: d?.toDate()
                      })
                    }}
                    placeholder="Date of birth"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Spacer space={16} />
          </>
        )
      }

      {
        entityType === EntityType.Business && (
          <Row>
            <Col xs={24}>
              <Form.Item
                name="authorizedRepTitle"
                rules={[
                  { required: true, message: 'Title of Authorized Representative is required' }
                ]}
                initialValue={
                  userType === EnumUserType.Main
                    ? globalStore.state.createTransaction.mainUser.authorizedRepTitle
                    : globalStore.state.createTransaction.secondaryUser.authorizedRepTitle
                }
              >
                <Input
                  placeholder="Title of Authorized Representative"
                  onChange={(e) => {
                    globalStore.dispatch({
                      type:
                      userType === EnumUserType.Main
                        ? 'UPDATE_MAIN_USER_AUTHORIZED_REP_TITLE'
                        : 'UPDATE_SECONDARY_USER_AUTHORIZED_REP_TITLE',
                      authorizedRepTitle: e.target.value
                    })
                  }}
                />
              </Form.Item>
              <Spacer space={24} />
            </Col>
          </Row>
        )
      }

      <Row>
        <Col xs={24}>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: 'Phone Number is required' },
              { pattern: PHONE_NUMBER_REGEX, message: 'Incorrect Phone Number' }
            ]}
            initialValue={
              userType === EnumUserType.Main
                ? globalStore.state.createTransaction.mainUser.phone
                : globalStore.state.createTransaction.secondaryUser.phone
            }
          >
            <Input
              placeholder="Phone number"
              inputMode={'tel'}
              onChange={(e) => {
                reformatPhoneNumber(e)
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Email is required' },
              { pattern: EMAIL_REGEX, message: 'Incorrect email format' }
            ]}
            initialValue={
              userType === EnumUserType.Main
                ? globalStore.state.createTransaction.mainUser.email
                : globalStore.state.createTransaction.secondaryUser.email
            }
          >
            <Input
              placeholder="Email"
              autoCapitalize="none"
              inputMode="email"
              onChange={(e) => {
                globalStore.dispatch({
                  type: userType === EnumUserType.Main
                    ? 'UPDATE_MAIN_USER_EMAIL'
                    : 'UPDATE_SECONDARY_USER_EMAIL',
                  email: e.target.value
                })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
