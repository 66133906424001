export enum EnumTransactionAction {
  FetchPreviousTransactions = 'FETCH_PREVIOUS_TRANSACTIONS',
  FetchPendingTransactions = 'FETCH_PENDING_TRANSACTIONS',
  FetchShipments = 'FETCH_SHIPMENTS',
  FetchUserPhone = 'FETCH_USER_PHONE',
  FetchUserInfo = 'FETCH_USER_INFO',
}

export enum EnumUserAction {
  UpdateTheme = 'UPDATE_THEME'
}
