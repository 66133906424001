import axios from 'axios'

import { getFirebaseAuth } from 'src/firebase/firebase'

import { API_URL } from './constants'

export const authApiAxiosWithRefresh = async () => authApiAxios((await getFirebaseAuth().currentUser?.getIdToken()) || '')

// Secure API access (with JWT tokens included)
export const authApiAxios = (token: string) => ((token: string) => axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${token}`
  }
}))(token)

// General API access
export const apiAxios = (() => axios.create({
  baseURL: API_URL
}))()
