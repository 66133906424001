import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'antd'

import { Button } from 'src/components/Button'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { ShipmentCard } from 'src/components/Shipment/ShipmentCard'
import { Spacer } from 'src/components/Spacer'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'
import { EnumTransactionAction } from 'src/store/types'

import './Shipments.scss'

export const Shipments = () => {
  const navigate = useNavigate()

  const globalStore = useContext(store)
  const { isAuthenLoaded } = useAuthen()

  const onRefresh = React.useCallback(() => {
    if (isAuthenLoaded) {
      globalStore.dispatch({
        type: EnumTransactionAction.FetchShipments
      })
    }
  }, [isAuthenLoaded])

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  const handleClickNewQuoteBtn = () => {
    navigate(EnumRoute.NewShipping)
  }

  const renderShipmentList = () => globalStore.state.prevShipments.shipments.map((s, idx) => <ShipmentCard key={idx} shipment={s} />)

  return (
    <HeaderLayout title="Shipping">
      <Row className="shipments-page">
        <Col span={20} offset={2}>
          <Row>
            <Col span={24} className="d-flex justify-center">
              <Button
                className="new-quote-btn"
                color="primary"
                type="primary"
                onClick={handleClickNewQuoteBtn}
              >
                Start a new quote
              </Button>
            </Col>
          </Row>
          <Row className="shipments__shipment-list">
            <Col span={24} className="d-flex flex-col justify-center">
              {renderShipmentList()}
            </Col>
          </Row>
          <Spacer space={20} />
        </Col>
      </Row>
    </HeaderLayout>
  )
}
