import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

import { LoadingModalProps } from './Modal.types'

import './LoadingModal.scss'

export default function LoadingModal({ title }: LoadingModalProps) {
  return (
    <Modal
      className="loading-modal"
      open
      centered
      closable={false}
      width="auto"
      footer={null}
    >
      <div className="loading-modal__title">{title}</div>
      <LoadingOutlined className="loading-modal__icon" />
    </Modal>
  )
}
