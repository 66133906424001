import React, { useState } from 'react'
import {
  CloseOutlined,
  DownloadOutlined
} from '@ant-design/icons'
import {
  Col, Form, ModalProps, Row, Typography
} from 'antd'
import classNames from 'classnames'
import {
  signInWithEmailAndPassword, updateEmail, updatePassword
} from 'firebase/auth'

import { Button } from 'src/components/Button'
import { Input, PasswordInput } from 'src/components/Input'
import { getFirebaseAuth } from 'src/firebase/firebase'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'

import KModal from './KModal'
import LoadingModal from './LoadingModal'

import './EditInfoModal.scss'

const { Title, Text } = Typography

interface FormValues {
  email: string
  currentPassword: string
  newPassword: string
  newPasswordRepeat: string
}

interface EditInfoModalProps extends ModalProps {
  onClose?: () => void
}

export default function EditInfoModal({ open, onClose }: EditInfoModalProps) {
  const [form] = Form.useForm()
  const { openMessage } = useMessage()
  const { reloadAuth, email: currentEmail } = useAuthen()
  const [errMessage, setErrMessage] = useState('')
  const [updatingModalVisible, setUpdatingModalVisible] = useState(false)
  const [newPasswordMatch, setNewPasswordMatch] = useState('')

  const handleClose = () => {
    setErrMessage('')
    form.resetFields()
    setNewPasswordMatch('')
    if (onClose) {
      onClose()
    }
  }

  const validateNewPasswordMatch = () => {
    const { newPassword, newPasswordRepeat } = form.getFieldsValue(['newPassword', 'newPasswordRepeat'])
    if (newPassword && newPassword.length >= 6 && newPasswordRepeat) {
      if (newPassword === newPasswordRepeat) {
        setNewPasswordMatch('yes')
      } else {
        setNewPasswordMatch('no')
      }
    } else {
      setNewPasswordMatch('')
    }
    return Promise.resolve()
  }

  const handleUpdateFailure = (error: any) => {
    const errorCode = error.code
    const errorMessage = error.message

    if (errorCode === 'auth/weak-password') {
      setErrMessage('Password is too weak!')
    } else if (errorCode === 'auth/invalid-email') {
      setErrMessage('The entered email is invalid')
    } else if (errorCode === 'auth/email-already-in-use') {
      setErrMessage('The entered email is invalid')
    } else if (errorCode === 'auth/wrong-password') {
      setErrMessage('The entered current password is invalid')
    } else {
      setErrMessage(errorMessage)
    }

    setUpdatingModalVisible(false)
  }

  const handleUpdate = async (values: FormValues) => {
    const {
      email, currentPassword, newPassword, newPasswordRepeat
    } = values
    const firebaseAuth = getFirebaseAuth()
    // Determine if we need to update the email
    const tmpUpdateEmail = email.length > 0 && firebaseAuth.currentUser?.email !== email

    if (newPassword !== newPasswordRepeat) {
      setErrMessage('Your new passwords must match')
      return
    }

    // Make sure we don't have failing scenarios
    if (currentPassword === newPassword) {
      setErrMessage('Your new password cannot be the same as your old password')
      return
    }

    // Now if we have to update anything then we need to log back in to make sure the user is authenticated
    const oldEmail = currentEmail || ''
    setUpdatingModalVisible(true)

    try {
      await signInWithEmailAndPassword(firebaseAuth, oldEmail, currentPassword)
    } catch (error: any) {
      handleUpdateFailure(error)
    }

    // Now if we need to update our email then do it
    if (tmpUpdateEmail && !!firebaseAuth.currentUser) {
      // First update it on the Firebase side
      try {
        await updateEmail(firebaseAuth.currentUser, email)
      } catch (error: any) {
        handleUpdateFailure(error)
        return
      }

      // Now we need to send an update to our backend to update the user's email
      try {
        await (await authApiAxiosWithRefresh()).post('/user/update/email', {
          oldEmail,
          newEmail: email
        })
      } catch (error: any) {
        setErrMessage('Email update error occurred, please contact support.')
        setUpdatingModalVisible(false)
        return
      }
    }

    // Now if we need to update the password we do it
    // Note: The entered email will be the new one always since we either updated
    //       it above or it was the same as before and didn't get updated
    if (firebaseAuth.currentUser) {
      try {
        await updatePassword(firebaseAuth.currentUser, newPassword)
      } catch (error: any) {
        handleUpdateFailure(error)
        return
      }
    }
    // If we made it here then clear things and tell the user
    reloadAuth()
    form.resetFields()
    form.setFieldValue('email', email)
    setErrMessage('')
    setNewPasswordMatch('')

    openMessage({
      type: EnumMessageType.SUCCESS,
      text1: 'Account successfully updated!',
      duration: 3
    })

    setUpdatingModalVisible(false)
  }

  return (
    <KModal
      title="Edit Account Info"
      open={open}
      onClose={handleClose}
      className="edit-info-modal"
    >
      <Row className={classNames('edit-info-modal__content')}>
        {
          updatingModalVisible && (
            <LoadingModal title="Updating info..." />
          )
        }
        <Col span={20} offset={2}>
          <Title className="edit-info-modal__title" level={4}>
            Edit Your Info Below
          </Title>
          <Form
            className="kuaay-form edit-info-modal__edit-info-form"
            onFinish={handleUpdate}
            layout="vertical"
            form={form}
          >
            <Form.Item
              name="email"
              label="Email"
              initialValue={
                getFirebaseAuth().currentUser?.email || ''
              }
              rules={[
                { required: true, message: 'Email is required' }
              ]}
            >
              <Input
                className="kuaay-input-v2"
                placeholder="Email"
                autoCapitalize="none"
                inputMode="email"
              />
            </Form.Item>
            <Form.Item
              name="currentPassword"
              label="Current Password"
              initialValue=""
              rules={[
                { required: true, message: 'Current Password is required' }
              ]}
            >
              <PasswordInput
                className="kuaay-password-input-v2"
                placeholder="Current Password"
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="New Password"
              initialValue=""
              rules={[
                { required: true, message: 'New Password is required' },
                { min: 8, message: 'Your New Password must be at least 8 characters.' }
              ]}
            >
              <PasswordInput
                autoCapitalize="none"
                placeholder="New Password"
                className="kuaay-password-input-v2"
              />
            </Form.Item>
            <Form.Item
              name="newPasswordRepeat"
              label="New Password Repeat"
              initialValue=""
              rules={[
                { required: true, message: 'New Password Repeat is required' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Passwords must match.'))
                  }
                })
              ]}
            >
              <PasswordInput
                autoCapitalize="none"
                placeholder="New Password Repeat"
                className="kuaay-password-input-v2"
              />
            </Form.Item>
            <div className="edit-info-modal__error-message">
              <Text>{errMessage}</Text>
            </div>
            <Row className="edit-info-modal__btn-group">
              <Col xs={12} className="edit-info-modal__left-col-btn">
                <Form.Item>
                  <Button
                    type="primary"
                    color="secondary"
                    className="edit-info-modal__btn edit-info-modal__cancel-btn"
                    icon={<CloseOutlined />}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={12} className="edit-info-modal__right-col-btn">
                <Form.Item>
                  <Button
                    type="primary"
                    color="primary"
                    className="edit-info-modal__btn edit-info-modal__update-btn"
                    icon={<DownloadOutlined rotate={180} />}
                    htmlType="submit"
                  >
                    Update Info
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </KModal>
  )
}
