import React, { createContext, useReducer } from 'react'

import { applyMiddleware } from './middleware/baseMiddleware'
import {
  authReducer,
  AuthState,
  initialAuthState
} from './reducers/authReducer'
import {
  createShipmentReducer,
  CreateShipmentState,
  initialCreateShipmentState
} from './reducers/createShipmentReducer'
import {
  createTransactionReducer,
  CreateTransactionState,
  initialCreateTransactionState
} from './reducers/createTransactionReducer'
import {
  initialMiscState,
  miscReducer,
  MiscState
} from './reducers/miscReducer'
import {
  initialPendingTransactionsState,
  pendingTransactionsReducer,
  PendingTransactionsState
} from './reducers/pendingTransactionsReducer'
import {
  initialPreviousTransactionsState,
  previousTransactionsReducer,
  PreviousTransactionsState
} from './reducers/previousTransactionsReducer'
import { initialPrevShipmentsState, prevShipmentsReducer, PrevShipmentsState } from './reducers/prevShipmentsReducer'
import {
  initialUserState,
  userReducer,
  UserState
} from './reducers/userReducer'
import { AppActions } from './rootActions'

export type AppState = {
  auth: AuthState;
  user: UserState;
  misc: MiscState;
  createTransaction: CreateTransactionState;
  previousTransactions: PreviousTransactionsState;
  pendingTransactions: PendingTransactionsState;
  createShipment: CreateShipmentState;
  prevShipments: PrevShipmentsState
};

const initialStoreState: AppState = {
  auth: initialAuthState,
  user: initialUserState,
  misc: initialMiscState,
  createTransaction: initialCreateTransactionState,
  createShipment: initialCreateShipmentState,
  previousTransactions: initialPreviousTransactionsState,
  pendingTransactions: initialPendingTransactionsState,
  prevShipments: initialPrevShipmentsState
}
const store = createContext<{ state: AppState; dispatch: React.Dispatch<any> }>(
  { state: initialStoreState, dispatch: () => null }
)
const { Provider } = store

const mainReducer = (
  {
    auth,
    user,
    misc,
    createTransaction,
    createShipment,
    previousTransactions,
    pendingTransactions,
    prevShipments
  }: AppState,
  action: AppActions
) => ({
  auth: authReducer(auth, action),
  user: userReducer(user, action),
  misc: miscReducer(misc, action),
  createTransaction: createTransactionReducer(createTransaction, action),
  createShipment: createShipmentReducer(createShipment, action),
  previousTransactions: previousTransactionsReducer(
    previousTransactions,
    action
  ),
  pendingTransactions: pendingTransactionsReducer(pendingTransactions, action),
  prevShipments: prevShipmentsReducer(prevShipments, action)
})

const StateProvider: React.FC<any> = ({ children }) => {
  const [state, rawDispatch] = useReducer(mainReducer, initialStoreState)
  const dispatch = applyMiddleware(rawDispatch)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, StateProvider }
