import React, { useMemo } from 'react'
import { Col, Row } from 'antd'

import { useAuthen } from 'src/hooks/useAuthen'
import { PendingTransactionInfo, TransactionInfo } from 'src/types/transactions'
import { ODOMETER_TYPES, ODOMETER_TYPES_ABBR } from 'src/utils/constants'
import { properCase } from 'src/utils/stringHelpers'
import { getUserInfoInTransaction } from 'src/utils/transaction'

import './TransactionDetailInfo.scss'

interface TransactionBasicDetailProps {
  transaction: TransactionInfo
}

export default function TransactionDetailInfo({ transaction }: TransactionBasicDetailProps) {
  const { currentUser } = useAuthen()

  const title = useMemo(() => {
    if (transaction) {
      if (
        !transaction.vehicle.year
        && !transaction.vehicle.make
        && !transaction.vehicle.model
      ) {
        return (transaction as PendingTransactionInfo).step
          ? 'No information provided'
          : 'Open for more information'
      }
      return `${transaction.vehicle.year ? `${transaction.vehicle.year} ` : ''}${
        transaction.vehicle.make ? `${transaction.vehicle.make} ` : ''
      }${transaction.vehicle.model ? `${transaction.vehicle.model} ` : ''}`
    }
    return ''
  }, [transaction])

  const role = useMemo(() => {
    if (transaction && currentUser?.uid) {
      // return transaction.mainUser.role === 'selfTransfer'
      //   ? 'Self-Transfer'
      //   : properCase(transaction.mainUser.role)
      return getUserInfoInTransaction(currentUser.uid, transaction)?.role
    }
    return undefined
  }, [transaction])

  const transactionDate = useMemo(() => {
    if (transaction) {
      return new Date(transaction.creationDate).toLocaleDateString()
    }
    return ''
  }, [transaction])

  return (
    <Row className="transaction-basic-detail">
      <Col span={24}>
        <Row>
          <Col span={16}>
            <div className="transaction-basic-detail__title">
              {title}
            </div>
            <div className="transaction-basic-detail__role">
              {properCase(role)}
            </div>
            <div className="transaction-basic-detail__date">
              {transactionDate}
            </div>
          </Col>
          <Col span={8} />
        </Row>

        <Row className="transaction-basic-detail__section">
          <Col span={24}>
            <div className="transaction-basic-detail__section__label">
              Sale Price:
            </div>
            <div className="transaction-basic-detail__section__value">
              {`$${transaction?.vehicle?.salePrice?.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}` ?? 'Unknown'}
            </div>
          </Col>
        </Row>

        <Row className="transaction-basic-detail__section">
          <Col span={24}>
            <div className="transaction-basic-detail__section__label">
              Purchase Date:
            </div>
            <div className="transaction-basic-detail__section__value">
              {transaction?.vehicle?.purchaseDate
                ? new Date(
                  transaction.vehicle.purchaseDate
                ).toLocaleDateString()
                : 'Unknown'}
            </div>
          </Col>
        </Row>

        <Row className="transaction-basic-detail__section">
          <Col span={24}>
            <div className="transaction-basic-detail__section__label">
              Odometer reading:
            </div>
            <div className="transaction-basic-detail__section__value">
              {transaction?.vehicle?.odometerReading ?? 'N/A'}
              {' '}
              {
                transaction?.vehicle?.odometerUnit
                && ODOMETER_TYPES_ABBR[
                  ODOMETER_TYPES.indexOf(transaction.vehicle.odometerUnit)
                ]
              }
            </div>
          </Col>
        </Row>
        {
          transaction?.vehicle?.vtPlate && (
            <Row className="transaction-basic-detail__section">
              <Col span={24}>
                <div className="transaction-basic-detail__section__label">
                  License Plate:
                </div>
                <div className="transaction-basic-detail__section__value">
                  { transaction.vehicle.vtPlate }
                </div>
              </Col>
            </Row>
          )
        }
        <Row className="transaction-basic-detail__section">
          <Col span={24}>
            <div className="transaction-basic-detail__section__label">
              Color:
            </div>
            <div className="transaction-basic-detail__section__value">
              {transaction?.vehicle?.color ?? 'Unknown'}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
