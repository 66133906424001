export enum EvenBusType {
  RESET_NEW_SHIPMENT_STATE = 'RESET_NEW_SHIPMENT_STATE'
}

export const EvenBus = {
  on(eventType: EvenBusType, callback: any) {
    document.addEventListener(eventType, callback)
  },
  dispatch(eventType: EvenBusType, data: any) {
    const event = new CustomEvent(eventType, { detail: data })
    document.dispatchEvent(event)
  },
  off(eventType: EvenBusType, callback: any) {
    document.removeEventListener(eventType, callback)
  }
}
