import React from 'react'
import {
  Input as AntInput, InputProps
} from 'antd'
import classNames from 'classnames'

import './Input.scss'

export default function Input(props: InputProps) {
  const {
    className, prefix, suffix, ...restProps
  } = props

  return (
    <AntInput
      className={
        classNames('kuaay-input', className, {
          'kuaay-input--has-prefix': !!prefix,
          'kuaay-input--has-suffix': !!suffix
        })
      }
      prefix={prefix ?? <span />}
      suffix={suffix}
      {...restProps}
    />
  )
}
