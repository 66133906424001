import { apiAxios, authApiAxiosWithRefresh } from './customAxios'

interface ZipResponse {
  state: string,
  city: string
}

export const resolveAddressFromZip = async (
  text: string
) => {
  const formattedText = text.replace(/\D/g, '')
  if (formattedText.length === 5) {
    const newZip = !Number.isNaN(parseInt(formattedText, 10)) ? formattedText : null
    if (!!newZip) {
      try {
        const zipResponse: ZipResponse = (
          await apiAxios.get(`/zip/${newZip}`)
        )?.data
        return { zip: newZip, city: zipResponse.city, state: zipResponse.state }
      } catch (e) {
        return null
      }
    }
  }
  return null
}
