import React from 'react'
import axios, { AxiosResponse } from 'axios'

import { AppState } from 'src/store/store'
import { TransactionInfo } from 'src/types/transactions'
import { EnumUserType } from 'src/utils/types'

import { authApiAxiosWithRefresh } from './customAxios'

export const generatePDF = async (
  userType: EnumUserType,
  globalStore: {
    state: AppState;
    dispatch: React.Dispatch<any>;
  }
) => {
  const passedInfo = userType === EnumUserType.Main
    ? {
      mainUser: globalStore.state.createTransaction.mainUser,
      vehicle: globalStore.state.createTransaction.vehicle
    }
    : {
      mainUser: globalStore.state.createTransaction.mainUser,
      secondaryUser: globalStore.state.createTransaction.secondaryUser,
      vehicle: globalStore.state.createTransaction.vehicle
    }
  return (await authApiAxiosWithRefresh()).post('/pdf/generate', passedInfo, {
    responseType: 'blob'
  })
}

export const generateTransactionPDF = async (transaction: TransactionInfo) => {
  const passedInfo = {
    mainUser: transaction.mainUser,
    secondaryUser: transaction.secondaryUser,
    vehicle: transaction.vehicle,
    transactionId: transaction.transactionId
  }
  return (await authApiAxiosWithRefresh()).post('/pdf/generate', passedInfo, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  })
}

export const retrieveTransactionPdf = async (transactionId: string) => (await authApiAxiosWithRefresh()).post('/pdf/retrieve', { transactionId }, {
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/pdf'
  }
})

export const getPDFData = async (
  userType: EnumUserType,
  globalStore: {
    state: AppState;
    dispatch: React.Dispatch<any>;
  }
) => {
  const pdfResp: AxiosResponse = (await generatePDF(userType, globalStore))!

  if (pdfResp) {
    return Buffer.from(pdfResp.data, 'binary').toString('base64')
  }
  // eslint-disable-next-line no-console
  console.log('No pdf response from server.')
  return undefined
}

export const getTransactionPDFData = async (
  transaction: TransactionInfo
): Promise<Blob|undefined> => {
  const pdfResp: AxiosResponse = (await generateTransactionPDF(transaction))!

  if (pdfResp) {
    return pdfResp.data as Blob
  }
  return undefined
}

export const getPdfFromLink = async (
  url: string
): Promise<Blob|undefined> => {
  const pdfResp: AxiosResponse = await axios.get(url, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  })

  if (pdfResp) {
    return pdfResp.data as Blob
  }
  return undefined
}
