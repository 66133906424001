import React from 'react'

import { Option, Select } from 'src/components/Select'
import { FUEL_TYPES } from 'src/utils/constants'

export default function FuelType(props: any) {
  const renderFuelTypes = () => FUEL_TYPES.map((fuelType) => (
    <Option key={`fuelType.${fuelType}`} value={fuelType}>{fuelType}</Option>
  ))
  return (
    <Select {...props}>
      {renderFuelTypes()}
    </Select>
  )
}
