import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Col, Row, Typography } from 'antd'
import classNames from 'classnames'

import { Button } from 'src/components/Button'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { AboutModal } from 'src/components/Modal'
import DeleteAccountModal from 'src/components/Modal/DeleteAccountModal'
import { Spacer } from 'src/components/Spacer'
import { Switch } from 'src/components/Switch'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useTheme } from 'src/hooks/useTheme'
import { EnumRoute } from 'src/router/types'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { EnumTheme } from 'src/utils/types'

import './settings.scss'

interface SettingItemProps {
  children: React.ReactNode
  onClick?: () => void
  title: string | React.ReactNode
}

function SettingItem({ children, title, onClick }: SettingItemProps) {
  const { theme } = useTheme()
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      onClick={handleClick}
      className={classNames('setting-item', theme)}
    >
      <div className="setting-item__title">
        {title}
      </div>
      <div className="setting-item__right-slot">
        {
          children
        }
      </div>
    </div>
  )
}

export default function Settings() {
  const [aboutModalVisible, setAboutModalVisible] = useState(false)
  const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const { theme, changeTheme } = useTheme()
  const { signOut, isAnonymous } = useAuthen()
  const { openMessage } = useMessage()
  const navigate = useNavigate()

  const openAboutModal = () => {
    setAboutModalVisible(true)
  }

  const closeAboutModal = () => {
    setAboutModalVisible(false)
  }

  const openDeleteAccountModal = () => {
    setDeleteAccountModalVisible(true)
  }

  const closeDeleteAccountModal = (e: any) => {
    setDeleteAccountModalVisible(false)
  }

  const handleDeleteAccount = async () => {
    setIsDeleting(true)
    try {
      await (await authApiAxiosWithRefresh()).delete('/user/delete')
      openMessage({
        text1: 'Account deleted',
        text2: 'Please allow up to five (5) business days for all data to be deleted',
        type: EnumMessageType.SUCCESS
      })
      setTimeout(async () => {
        await signOut()
        navigate(EnumRoute.Home)
      }, 3000)
    } catch (e) {
      // console.log("Error deleting account:", e);
      openMessage({
        text1: 'Failed to delete account',
        text2: 'Please try again in a few minutes or contact support (support@kuaay.com) if the probem persists',
        type: EnumMessageType.ERROR
      })
    } finally {
      setIsDeleting(false)
    }
    setDeleteAccountModalVisible(false)
  }

  return (
    <HeaderLayout title="Settings" subtitle="Tune and Tweak" hasGoBack>
      <Row className="settings-page">
        <Col className="settings-page__content flex-col" span={20} offset={2}>
          <div className="settings-page__title">More Settings Coming Soon!</div>
          {/* <SettingItem onClick={changeTheme} title="Use dark theme">
            <Switch
              className="theme-switch"
              label="Use dark theme"
              checked={theme === EnumTheme.Dark}
              onChange={changeTheme}
            />
          </SettingItem> */}
          <SettingItem onClick={openAboutModal} title="About Pacer">
            <InfoCircleOutlined />
          </SettingItem>
          {
            !isAnonymous && (
              <>
                <Spacer space={8} className="flex-grow-1" />
                <SettingItem
                  title={(
                    <Typography.Text className="font-weight-400">
                      <div>
                        Delete my Pacer Account
                      </div>
                      <div>
                        (and all associated data)
                      </div>
                    </Typography.Text>
                  )}
                >
                  <Button
                    color="danger"
                    onClick={openDeleteAccountModal}
                  >
                    Delete
                  </Button>
                </SettingItem>
              </>
            )
          }
        </Col>
      </Row>
      <AboutModal open={aboutModalVisible} onClose={closeAboutModal} />
      <DeleteAccountModal
        open={deleteAccountModalVisible}
        loading={isDeleting}
        onCancel={closeDeleteAccountModal}
        onOk={handleDeleteAccount}
      />
    </HeaderLayout>
  )
}
