import React, { useState } from 'react'
import { RightOutlined } from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import classNames from 'classnames'

import logo from 'src/assets/logo.png'
import logoWhite from 'src/assets/logo-white.png'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { useTheme } from 'src/hooks/useTheme'
import { EnumTheme } from 'src/utils/types'

import { AboutItemProps, AboutModalProps } from './Modal.types'
import PrivatePolicyModal from './PrivatePolicyModal'
import TermsAndConditionsModal from './TermsAndConditionsModal'

import './AboutModal.scss'

function AboutItem({ title, onClick }: AboutItemProps) {
  const { theme } = useTheme()

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div
      onClick={handleClick}
      className={classNames('about-item', theme)}
    >
      <div className="about-item__title">
        {title}
      </div>
      <RightOutlined />
    </div>
  )
}

export default function AboutModal({ open, onClose, ...rest }: AboutModalProps) {
  const { theme } = useTheme()
  const [privatePolicyVisible, setPrivatePolicyVisible] = useState(false)
  const [termAndConditionVisible, setTermAndConditionVisible] = useState(false)
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  return (
    <Modal className="about-modal" closable={false} open={open} footer={null} {...rest}>
      <HeaderLayout title="About" subtitle="Pacer" hasGoBack onGoBack={handleClose}>
        <Row className="about-modal__content">
          <Col span={20} offset={2}>
            <Row>
              <Col className="kuaay-logo" sm={24}>
                <img alt="Pacer Logo" src={theme === EnumTheme.Dark ? logoWhite : logo} />
              </Col>
            </Row>
            <Row>
              <Col className="kuaay-description" sm={24}>
                Here at Pacer we strive to make the car licensing process as easy as
                possible! Pacer was founded in 2021 by three people who just knew that
                there had to be an easier way to handle all of the annoying paperwork
                that comes with buying a car.
              </Col>
            </Row>
            <AboutItem title="Private Policy" onClick={() => setPrivatePolicyVisible(true)} />
            <AboutItem title="Terms and Conditions" onClick={() => setTermAndConditionVisible(true)} />
          </Col>
        </Row>
        <PrivatePolicyModal
          open={privatePolicyVisible}
          onClose={() => setPrivatePolicyVisible(false)}
        />
        <TermsAndConditionsModal
          open={termAndConditionVisible}
          onClose={() => setTermAndConditionVisible(false)}
        />
      </HeaderLayout>
    </Modal>
  )
}
