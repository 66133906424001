import { VehicleBodyType } from './transactions'

export enum OperableType {
  OPERABLE = 'OPERABLE',
  INOPERABLE = 'INOPERABLE'
}

export type ShipmentAddress = {
  address: string
  zip: number | string
  city: string
  state: string | null
}

export type ShipmentContact = {
  name: string
  phone: string
  email: string
}

export enum ShipmentVehicleType {
  Car = 'Car',
  MiniVan = 'Mini-van',
  Motorcycle = 'Motorcycle',
  Suv = 'SUV',
  Van = 'Van'
}

export type ShipmentVehicle = {
  vin: string
  year: number
  make: string
  model: string
  bodyType: ShipmentVehicleType
  operable: OperableType
}

export type CreateShipmentVehicle = {
  [K in keyof ShipmentVehicle]: ShipmentVehicle[K] | null
}

export enum ShipmentStatus {
  NOT_STARTED = 'not_started',
  CREATED = 'created',
  DRIVER_ASSIGNED = 'driver_assigned',
  PICKED_UP = 'picked_up',
  COMPLETED = 'completed',
  CANCELED = 'canceled'
}

export interface ShipmentRate {
  identity: string
  label: string
  payment: string
  pickupWindow: number
  price: number
}

export interface ShipmentInfo {
  shipmentId?: string
  shippingDate: Date
  pickupAddress: ShipmentAddress
  pickupContact: ShipmentContact
  deliveryAddress: ShipmentAddress
  deliveryContact: ShipmentContact
  enclosedTrailer: boolean
  userId?: string
  vehicles: ShipmentVehicle[]
  notesToDriver: string
  price: number
  complete: boolean
  status?: ShipmentStatus
  creationDate: Date
  lastStatusUpdatedDate: Date
  storeDate: Date
  paymentConfirmDate: Date
  transactionId?: string
  publicLink?: string
  rates?: ShipmentRate[]
  selectedRateId?: string
}

export interface ShipmentAvailableVehicle {
  vin?: string
  year?: number
  make?: string
  model?: string
  bodyType?: ShipmentVehicleType
}
