import React, { useContext } from 'react'
import {
  Col,
  Form,
  Row
} from 'antd'

import { Input } from 'src/components/Input'
import { Spacer } from 'src/components/Spacer'
import { USStateSelect } from 'src/components/USStateSelect'
import { useQuery } from 'src/hooks/useQuery'
import { store } from 'src/store/store'
import { ZIP_CODE_REGEX } from 'src/utils/constants'
import { EnumUserType } from 'src/utils/types'
import { EntityType } from 'src/views/new-transaction/types'

import { useHandleSubmit } from './NewTransactionUserInformation.hooks'

interface ContactMailingProps {
  entityType: EntityType,
  form?: any
}

export default function MailingDifference(props: ContactMailingProps) {
  const { form } = props
  const globalStore = useContext(store)
  const query = useQuery()
  const userType = query.get('userType') as EnumUserType ?? EnumUserType.Secondary
  const { handleZipUpdate } = useHandleSubmit(userType)

  return (
    <Row>
      <Col xs={24}>
        <Form.Item
          name="addressMailing"
          initialValue={
            userType === EnumUserType.Main
              ? globalStore.state.createTransaction.mainUser.addressMailing
              : globalStore.state.createTransaction.secondaryUser.addressMailing
          }
          shouldUpdate={(prevValues, curValues) => prevValues.addressMailing !== curValues.addressMailing}
        >
          <Input
            placeholder="Mailing Address"
            onChange={(e) => {
              globalStore.dispatch({
                type: userType === EnumUserType.Main
                  ? 'UPDATE_MAIN_USER_ADDRESS_MAILING'
                  : 'UPDATE_SECONDARY_USER_ADDRESS_MAILING',
                addressMailing: e.target.value
              })
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name="addressMailing2"
          initialValue={
            userType === EnumUserType.Main
              ? globalStore.state.createTransaction.mainUser.addressMailing2
              : globalStore.state.createTransaction.secondaryUser.addressMailing2
          }
          shouldUpdate={(prevValues, curValues) => prevValues.addressMailing2 !== curValues.addressMailing2}
        >
          <Input
            placeholder="Mailing Apartment, suite, unit, etc."
            onChange={(e) => {
              globalStore.dispatch({
                type: userType === EnumUserType.Main
                  ? 'UPDATE_MAIN_USER_ADDRESS_MAILING2'
                  : 'UPDATE_SECONDARY_USER_ADDRESS_MAILING2',
                addressMailing2: e.target.value
              })
            }}
          />
        </Form.Item>
      </Col>

      <Col xs={8}>
        <Form.Item
          name="zipMailing"
          rules={[
            { required: true, message: 'Mail Zip is required' },
            { pattern: ZIP_CODE_REGEX, message: 'Incorrect Mail Zip Code' }
          ]}
          initialValue={
            userType === EnumUserType.Main
              ? globalStore.state.createTransaction.mainUser.zipMailing
              : globalStore.state.createTransaction.secondaryUser.zipMailing
          }
          shouldUpdate={(prevValues, curValues) => prevValues.zipMailing !== curValues.zipMailing}
        >
          <Input
            maxLength={5}
            placeholder="Mail Zip"
            inputMode={'numeric'}
            onChange={(e) => {
              handleZipUpdate(e.target.value, form, true)
            }}
          />
        </Form.Item>
      </Col>
      <Col offset={1} xs={15}>
        <Form.Item
          name="cityMailing"
          rules={[{ required: true, message: 'Mail City is required' }]}
          initialValue={
            userType === EnumUserType.Main
              ? globalStore.state.createTransaction.mainUser.cityMailing
              : globalStore.state.createTransaction.secondaryUser.cityMailing
          }
          shouldUpdate={(prevValues, curValues) => prevValues.cityMailing !== curValues.cityMailing}
        >
          <Input
            placeholder="Mailing City"
            onChange={(e) => {
              globalStore.dispatch({
                type: userType === EnumUserType.Main
                  ? 'UPDATE_MAIN_USER_CITY_MAILING'
                  : 'UPDATE_SECONDARY_USER_CITY_MAILING',
                cityMailing: e.target.value
              })
            }}
          />
        </Form.Item>
      </Col>

      <Col xs={24}>
        <Form.Item
          name="stateMailing"
          rules={[{ required: true, message: 'Mailing State is required' }]}
          initialValue={
            userType === EnumUserType.Main
              ? globalStore.state.createTransaction.mainUser.stateMailing
              : globalStore.state.createTransaction.secondaryUser.stateMailing
          }
          shouldUpdate={(prevValues, curValues) => prevValues.stateMailing !== curValues.stateMailing}
        >
          <USStateSelect
            onChange={(data) => {
              globalStore.dispatch({
                type: userType === EnumUserType.Main
                  ? 'UPDATE_MAIN_USER_STATE_MAILING'
                  : 'UPDATE_SECONDARY_USER_STATE_MAILING',
                stateMailing: data
              })
            }}
            placeholder="Mailing State"
          />
        </Form.Item>
        <Spacer space={24} />
      </Col>
    </Row>
  )
}
