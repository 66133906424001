import React from 'react'
import {
  Modal,
  Row,
  Typography
} from 'antd'

import { Button } from 'src/components/Button'
import { useDisplay } from 'src/hooks/useDisplay'

import { DeleteAccountModalProps } from './Modal.types'

import './DeleteAccountModal.scss'

export default function DeleteAccountModal({
  open,
  onOk,
  onCancel,
  loading
}: DeleteAccountModalProps) {
  const { windowWidth, isPhoneDevice } = useDisplay()
  return (
    <Modal
      className="delete-account-modal"
      open={open}
      centered
      closable={false}
      width={windowWidth < 464 ? 'auto' : 400}
      footer={null}
    >
      <div className="delete-account-modal__title">
        Are you sure you want to delete your Pacer account and all associated data?
      </div>
      <Row className="delete-account-modal__btn-group">
        <Button
          color="secondary"
          className="delete-account-modal__btn-group__cancel"
          onClick={(e) => onCancel?.(e as never)}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          color="danger"
          className="delete-account-modal__btn-group__continue"
          onClick={(e) => onOk?.(e as never)}
          loading={loading}
        >
          Delete
        </Button>
      </Row>
    </Modal>
  )
}
