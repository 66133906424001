import React, {
  useContext, useEffect, useMemo, useState
} from 'react'
import type { RadioChangeEvent } from 'antd'
import {
  Col,
  Radio,
  Row,
  Typography
} from 'antd'
import classNames from 'classnames'

import { Spacer } from 'src/components/Spacer'
import { store } from 'src/store/store'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'
import { EntityType } from 'src/views/new-transaction/types'

import Business from './EntityBusiness'
import Individual from './EntityIndividual'

import './styles.scss'

const { Title, Text } = Typography

interface NewTransactionUserInformationProps {
  userType: EnumUserType
}

function NewTransactionUserInformation(
  props: NewTransactionUserInformationProps
) {
  const { userType } = props
  const globalStore = useContext(store)
  const { role: userRole } = transactionUserInfo(userType, globalStore)

  const roleName = useMemo(() => {
    switch (userRole) {
    case EnumUserRole.Buyer:
      return 'Buyer'
    case EnumUserRole.Seller:
      return 'Seller'
    // case EnumUserRole.SelfTransfer:
    default:
      return 'User'
    }
  }, [userRole])
  const isBusiness = useMemo(() => {
    if (userType === EnumUserType.Main) {
      return globalStore.state.createTransaction.mainUser.isBusiness
    }
    return globalStore.state.createTransaction.secondaryUser.isBusiness
  }, [
    userType,
    globalStore.state.createTransaction.secondaryUser.isBusiness,
    globalStore.state.createTransaction.mainUser.isBusiness
  ])
  const [entityType, setEntityType] = useState(isBusiness
    ? EntityType.Business
    : EntityType.Individual)

  useEffect(() => {
    const isBusiness = entityType === EntityType.Business
    if (userType === EnumUserType.Main) {
      globalStore.dispatch({
        type: 'UPDATE_MAIN_USER_IS_BUSINESS',
        isBusiness
      })
    } else {
      globalStore.dispatch({
        type: 'UPDATE_SECONDARY_USER_IS_BUSINESS',
        isBusiness
      })
    }
  }, [entityType])

  const onChange = (e: RadioChangeEvent) => {
    setEntityType(e.target.value)
  }

  const renderForm = () => {
    if (entityType === EntityType.Individual) {
      return <Individual userType={userType} />
    }
    return <Business userType={userType} />
  }

  return (
    <Row className={classNames('new-transaction-page')}>
      <Col span={20} offset={2}>
        <Title className="new-transaction-page__title" level={4}>
          { `${roleName} Information` }
        </Title>
        <Spacer space={14} />
        <Text className="new-transaction-page__text-under-title">
          { `What type of entity is the ${roleName.toLowerCase()}?` }
        </Text>
        <br />
        <Radio.Group
          className="new-transaction-page__entity-type"
          onChange={onChange}
          value={entityType}
        >
          <Radio className="entity-type__individual" value={EntityType.Individual}>Individual</Radio>
          <Radio className="entity-type__business" value={EntityType.Business}>Business</Radio>
        </Radio.Group>
        <Spacer space={24} />
        {renderForm()}
      </Col>
    </Row>
  )
}

export default NewTransactionUserInformation
