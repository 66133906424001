import React, { useEffect, useMemo, useState } from 'react'
import { Document, Page } from 'react-pdf'
import {
  Col, Modal, Row
} from 'antd'

import { HeaderLayout } from 'src/components/HeaderLayout'
import { useDisplay } from 'src/hooks/useDisplay'

import { TransactionPdfModalProps } from './types'

import './TransactionPdfModal.scss'

export default function TransactionPdfModal(
  {
    open,
    pdfBlob,
    onClose
  }: TransactionPdfModalProps
) {
  const { isMobileDevice, windowWidth } = useDisplay()
  const [totalPages, setTotalPages] = useState(0)
  const [pdfWidth, setPdfWidth] = useState(0)

  useEffect(() => {
    setPdfWidth(isMobileDevice ? windowWidth : 1024 - 32)
  }, [open])

  const onDocumentLoad = ({ numPages }: { numPages: number}) => {
    setTotalPages(numPages)
  }

  const onDocumentLoadFailed = (err: any) => {
    // eslint-disable-next-line no-console
    console.log('failed to load pdf doc', err)
  }

  const pages = useMemo(() => new Array(totalPages).fill(1)
    .map((x, i) => i + 1)
    .map((page) => <Page width={pdfWidth} pageNumber={page} />), [totalPages])

  return (
    <Modal
      className="transaction-pdf-modal"
      closable={false}
      open={open}
      footer={null}
    >
      <HeaderLayout
        title="Transaction PDF"
        subtitle="Preview"
        hasGoBack
        onGoBack={onClose}
      >
        <Row className="transaction-pdf-modal__content">
          <Col span={24} offset={0}>
            <Document
              file={pdfBlob}
              onLoadSuccess={onDocumentLoad}
              onLoadError={onDocumentLoadFailed}
            >
              {
                pages
              }
            </Document>
          </Col>
        </Row>
      </HeaderLayout>
    </Modal>
  )
}
