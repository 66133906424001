import React, { useContext } from 'react'
import {
  useLocation,
  useNavigate
} from 'react-router-dom'
import {
  ExclamationCircleFilled,
  HomeOutlined,
  PlusSquareOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Col, Row } from 'antd'

import { ShippingIcon } from 'src/components/Icons/ShippingIcon'
import { Navigation } from 'src/components/Navigation'
import { EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'

import './BottomNavigation.scss'

export default function BottomNavigation() {
  const navigate = useNavigate()
  const location = useLocation()
  const globalStore = useContext(store)

  const bottomNavItems = [
    {
      title: 'Home',
      route: EnumRoute.Home,
      icon: <HomeOutlined className="nav-item-icon" />,
      activeIcon: <HomeOutlined className="nav-item-icon" style={{ fontSize: '18px', color: '#fff' }} />
    },
    {
      title: 'Checkout',
      route: EnumRoute.NewTransaction,
      icon: <PlusSquareOutlined className="nav-item-icon" />,
      activeIcon: <PlusSquareOutlined className="nav-item-icon" />
    },
    {
      title: 'Shipping',
      route: EnumRoute.ShippingAndTracking,
      icon: <ShippingIcon className="nav-item-icon" />,
      activeIcon: <ShippingIcon className="nav-item-icon" />
    },
    {
      title: 'Account',
      route: EnumRoute.Account,
      icon: <UserOutlined className="nav-item-icon" />,
      activeIcon: <UserOutlined className="nav-item-icon" />,
      alertIcon: globalStore.state.previousTransactions.hasVerifyTimeout ? <ExclamationCircleFilled className="alert-icon" /> : undefined
    }
  ]
  return (
    <Row>
      <Col span={24}>
        <Navigation
          className="bottom-navigation"
          slidePosition="top"
          items={bottomNavItems}
          selected={location.pathname}
          onItemClick={(item: any) => navigate(item.route)}
        />
      </Col>
    </Row>
  )
}
