import React, { useContext } from 'react'
import { Col, Row, Typography } from 'antd'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'

import './styles.scss'

const { Title, Text } = Typography

interface NewTransactionSignatureAppInfoProps {
  userType: EnumUserType,
}

export default function NewTransactionSignatureAppInfo({
  userType
}: NewTransactionSignatureAppInfoProps) {
  const globalStore = useContext(store)
  const { navigateToStep } = useNewTransaction()
  const userRole = transactionUserInfo(userType, globalStore).role

  const handleContinueClick = () => {
    // if (userRole === EnumUserRole.SelfTransfer) {
    //   navigateToStep({ step: EnumNewTransactionParams.Signature })
    // } else {
    //   navigateToStep({ step: EnumNewTransactionParams.SignatureOdometer })
    // }
    navigateToStep({ step: EnumNewTransactionParams.SignatureOdometer })
  }

  return (
    <Row className="new-transaction-application-info">
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <Title className="new-transaction-application-info__title" level={4}>Application Disclosure</Title>
        <div style={{ textAlign: 'left' }}>
          <Text>
            Statements and warrants herein are certified under penalty of
            23 V.S.A. §202, §203, §2082, and 32 V.S.A. §§ 8901-8915.
            <br />
            <br />
            The owner certifies that this vehicle
            <br />
            1) is properly equipped and in good mechanical
            condition;
            <br />
            2) was placed into use on or before the date this
            application was signed;
            <br />
            3) currently has liability insurance in effect as
            required by 23 V.S.A. §800 (a).
            <br />
            If transfer of plates, the owner and/or this vehicle are
            not under suspension pursuant to 23 V,S,A, §3009(b). As the
            applicant for registration of a commercial motor vehicle, I
            hereby declare that I have knowledge of the Federal Motor
            Carrier Safety Regulations, Title 49 of the Code of Federal
            Regulations, as adopted by the State of Vermont.
          </Text>
        </div>
        <Spacer className="flex-grow-1" space={16} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="primary"
          onClick={handleContinueClick}
        >
          Agree and Continue
        </Button>
      </Col>
    </Row>
  )
}
