import { useContext } from 'react'

import { store } from '../store/store'
import { USE_PROD_STRIPE } from '../utils/constants'
import { authApiAxiosWithRefresh } from '../utils/customAxios'

export const useStripe = () => {
  const globalStore = useContext(store)

  const fetchStripePublicKey = async (
    retry: boolean = false
  ): Promise<undefined | string> => {
    try {
      const publicKeyResponse = await (
        await authApiAxiosWithRefresh()
      ).post('/payment/publishable-key', {
        prod: USE_PROD_STRIPE
      })
      globalStore.dispatch({
        type: 'UPDATE_STRIPE_PUBLISHABLE_KEY',
        stripePublishableKey: publicKeyResponse.data
      })
      return publicKeyResponse.data
    } catch (err) {
      if (retry) {
        // eslint-disable-next-line no-console
        console.error('Failed to stripe public key! RETRYING!', err)
        return fetchStripePublicKey(false)
      }
      // eslint-disable-next-line no-console
      console.error('Failed to stripe public key!', err)
      return undefined
    }
  }

  return {
    fetchStripePublicKey
  }
}
