export enum StripeTxnStatus {
  SUCCEEDED = 'succeeded',
  PROCESSING = 'processing',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

export interface KnownIssuesAgreement {
  isAgreed: boolean
  rejectReasons: string
}

export interface CreateTxnPaymentTransfer {
  enabled: boolean
  protectionEnabled: boolean
  buyer: {
    knownIssuesAgreement: KnownIssuesAgreement
  },
  seller: {
    knownIssuesDisclosure: string
  }
}

export enum PaymentTransferStatus {
  NONE = 'none',
  PENDING = 'pending',
  VERIFIED = 'verified',
  RELEASE_PAYMENT_PENDING = 'release_payment_pending',
  RELEASE_PAYMENT_ACK = 'release_payment_ack',
  REFUND_PENDING = 'refund_pending',
  REFUND_ACK = 'refund_ack',
  COMPLETED = 'completed',
  FAILED = 'failed',
  TRANSFER_PAYMENT = 'transfer_payment',
  TRANSFER_PAYMENT_ACK = 'transfer_payment_ack',
  VEHICLE_SHIPPED = 'vehicle_shipped'
}

export interface StripePaymentDetails {
  transactionId: string
  status?: StripeTxnStatus
  paidAt?: Date
  refundedAt?: Date
}

export interface TransactionReport {
  message: string
  reportDate: Date
}

export interface PaymentTransferUser {
  stripeTransaction: StripePaymentDetails
  reports: TransactionReport[]
}

export interface PaymentTransferBuyer extends PaymentTransferUser{
  isVerified: boolean
  knownIssuesAgreement: KnownIssuesAgreement
}

export interface PaymentTransferSeller extends PaymentTransferUser{
  knownIssuesDisclosure: string
}

export interface PaymentTransferInfo {
  enabled: boolean
  protectionEnabled: boolean
  status: PaymentTransferStatus
  buyer: PaymentTransferBuyer
  seller: PaymentTransferSeller
  autoVerifyDate: Date
}

export interface PaymentTransferHistory {
  transactionId: string
  status: PaymentTransferStatus
  createdAt: Date
  metadata: any
}
