import React, {
  useCallback, useEffect, useMemo, useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Col, Form, Modal, Row, Typography
} from 'antd'
import dayjs from 'dayjs'

import { Button } from 'src/components/Button'
import { Input } from 'src/components/Input'
import { LoadingModal } from 'src/components/Modal'
import ConfirmModal from 'src/components/Modal/ConfirmModal'
import { Spacer } from 'src/components/Spacer'
import { TransactionFeeInfoModal } from 'src/components/Transaction/index'
import TransactionPdfModal from 'src/components/Transaction/TransactionPdfModal'
import TransactionReportIssueModal from 'src/components/Transaction/TransactionReportIssueModal'
import { useAuthen } from 'src/hooks/useAuthen'
import { useDisplay } from 'src/hooks/useDisplay'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewShipment } from 'src/hooks/useNewShipment'
import { EnumKycContext, EnumRoute } from 'src/router/types'
import { StripeTxnStatus } from 'src/types/paymentTransfer'
import { TransactionInfo } from 'src/types/transactions'
import { KycStatus } from 'src/types/users'
import { PAYMENT_TRANSFER_HISTORY_MAPPING } from 'src/utils/constants'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { retrieveTransactionPdf } from 'src/utils/pdfHelpers'
import { vehicleVerifiable } from 'src/utils/transaction'
import { EnumUserRole } from 'src/utils/types'

import './TransactionDetailActions.scss'

const { Text } = Typography

interface TransactionDetailActionsProps {
  transaction: TransactionInfo
  userRole: EnumUserRole
  onUpdateTransaction?: (transaction: TransactionInfo) => void
  onRefresh?: () => void
}

const ActionGroup = ({ children, label }: { children: any, label: string}) => (
  <div className={'transaction-detail-actions__group'}>
    <div className={'transaction-detail-actions__group__label'}>
      { label }
      :
    </div>
    <div className={'transaction-detail-actions__group__body'}>
      {children}
    </div>
    <Spacer space={24} />
  </div>
)

export default function TransactionDetailActions({ transaction, userRole, onRefresh }: TransactionDetailActionsProps) {
  const { currentUser } = useAuthen()
  const { openMessage } = useMessage()
  const navigate = useNavigate()
  // const storage = useMemo(() => getFirebaseStorage(), [])
  // const docRef = useMemo(() => ref(storage, 'gs://default-bucket/kuaay-6fdf661f-a7bd-410c-b0b4-b97028fda9b3.pdf'), [storage])
  const { resetNewShipmentState } = useNewShipment()
  const [feeModalVisible, setFeeModalVisible] = useState(false)
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [isEmailing, setIsEmailing] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [showReportIssueModal, setShowReportIssueModal] = useState(false)
  const [showCompletePaymentTransferModal, setShowCompletePaymentTransferModal] = useState(false)
  const [showPTHistoryModal, setShowPTHistoryModal] = useState(false)
  const [localPdfBlob, setLocalPdfBlob] = useState<Blob|undefined>(undefined)
  const [pdfModalVisible, setPdfModalVisible] = useState(false)
  const { paymentTransfer, paymentTransferHistories } = transaction
  const stripeTxn = paymentTransfer?.buyer?.stripeTransaction

  const isTransferred = useMemo(() => {
    return !!stripeTxn?.transactionId
      && [StripeTxnStatus.SUCCEEDED].includes(stripeTxn.status as unknown as StripeTxnStatus)
  }, [transaction])

  const { isMobileDevice, windowWidth } = useDisplay()
  const confirmModalWidth = useMemo(() => (isMobileDevice ? windowWidth - 48 : 800), [isMobileDevice, windowWidth])

  const [kycStatus, setKycStatus] = useState(KycStatus.NOT_STARTED)

  useEffect(() => {
    fetchKycStatus()
  }, [])

  const fetchKycStatus = async () => {
    try {
      const res = await (await authApiAxiosWithRefresh()).get(`kyc/status?email=${currentUser?.email}`)
      setKycStatus(res.data.status)
    } catch (error: any) {
      openMessage({
        text1: 'There was an error while retrieving KYC status. Please try again soon.',
        type: EnumMessageType.ERROR
      })
    }
  }

  const ptHistoryMapping = useMemo(() => {
    if (userRole === EnumUserRole.Buyer) {
      return paymentTransfer?.protectionEnabled
        ? PAYMENT_TRANSFER_HISTORY_MAPPING.BUYER_PROTECTION
        : PAYMENT_TRANSFER_HISTORY_MAPPING.BUYER_NON_PROTECTION
    }
    return paymentTransfer?.protectionEnabled
      ? PAYMENT_TRANSFER_HISTORY_MAPPING.SELLER_PROTECTION
      : PAYMENT_TRANSFER_HISTORY_MAPPING.SELLER_NON_PROTECTION
  }, [paymentTransfer, userRole])

  const completeTransactionBtnTitle = useMemo(() => {
    if (transaction?.shipment?.enabled && paymentTransfer?.autoVerifyDate) {
      const targetCompleteDate = dayjs(paymentTransfer?.autoVerifyDate)
      const hours = targetCompleteDate.diff(new Date(), 'hours')
      let timeTextInfo = ''
      if (hours > 1) {
        timeTextInfo = `(${hours} hours left)`
      } else if (hours === 1) {
        timeTextInfo = `(${hours} hour left)`
      } else {
        const minutes = targetCompleteDate.diff(new Date(), 'minutes')
        timeTextInfo = minutes > 1 ? `(${minutes} minutes left)` : '(1 minute left)'
      }
      return `Complete transaction \n${timeTextInfo}`
    }
    return 'Complete transaction'
  }, [transaction])

  const renderBuyerPaymentTransfer = useCallback(() => {
    const stripeTxn = paymentTransfer?.buyer?.stripeTransaction
    const isProcessing = stripeTxn?.status === StripeTxnStatus.PROCESSING
    const isFailed = stripeTxn?.status === StripeTxnStatus.FAILED

    if (isTransferred) {
      return (
        <div className="transaction-detail-actions__group__payment-transfer">
          {
            vehicleVerifiable(transaction) && (
              <Button
                type="primary"
                color="success"
                onClick={() => { setShowCompletePaymentTransferModal(true) }}
              >
                {completeTransactionBtnTitle}
              </Button>
            )
          }
          <Button
            type="primary"
            color="danger"
            onClick={() => { setShowReportIssueModal(true) }}
          >
            {getIssueTitle(!!transaction?.paymentTransfer?.buyer?.reports?.length)}
          </Button>
        </div>
      )
    }

    return (
      <div className="transaction-detail-actions__group__payment-transfer">
        <div>
          {
            isProcessing
              ? 'The bank is processing the transaction. It might take 1-2 business days'
              : (
                isFailed
                  ? (
                    <div className={'text-center text--error'}>
                      The payment has been failed.
                      <br />
                      Please make sure you have sufficient balance on your bank account.
                    </div>
                  )
                  : "You haven't started a payment transfer for this transaction."
              )
          }
        </div>
        <Button
          type="primary"
          color="secondary"
          disabled={stripeTxn?.status === StripeTxnStatus.PROCESSING}
          onClick={() => {
            handleClickCompletePaymentTransfer()
          }}
        >
          Complete Payment transfer
        </Button>
      </div>
    )
  }, [transaction, userRole, isTransferred])

  const renderSellerPaymentTransfer = useCallback(() => {
    if (kycStatus === KycStatus.COMPLETED) {
      return (
        <div className="transaction-detail-actions__group__payment-transfer">
          <Button
            type="primary"
            color="danger"
            onClick={() => { setShowReportIssueModal(true) }}
          >
            {getIssueTitle(!!transaction?.paymentTransfer?.seller?.reports?.length)}
          </Button>
        </div>
      )
    }
    return (
      <div className="transaction-detail-actions__group__payment-transfer">
        <div>
          Identity verification is required to receive payments.
        </div>
        <Button
          type="primary"
          color="primary"
          onClick={handleClickStartIdentityVerification}
        >
          Start Identity Verification
        </Button>
      </div>
    )
  }, [transaction, userRole, kycStatus])

  const getIssueTitle = (existingIssue: boolean) => (existingIssue ? 'Report another issue' : 'Report an issue')

  const handleCompleteTransaction = async () => {
    try {
      await (await authApiAxiosWithRefresh()).post(`/payment-transfer/${transaction?.transactionId}/verify`)
      setShowCompletePaymentTransferModal(false)
      openMessage({
        text1: 'Transaction status has been updated.',
        type: EnumMessageType.SUCCESS
      })
      if (onRefresh) {
        onRefresh()
      }
    } catch (error: any) {
      setShowCompletePaymentTransferModal(false)
      openMessage({
        text1: 'There was an error while completing the transaction. Please try again soon.',
        type: EnumMessageType.ERROR
      })
    }
  }

  const handleClickStartIdentityVerification = async () => {
    try {
      const onboardRes: any = await (await authApiAxiosWithRefresh()).post('kyc/onboard', {
        context: EnumKycContext.TransactionDetail,
        transactionId: transaction.transactionId
      })
      window.location.href = onboardRes.data.url
    } catch (error: any) {
      openMessage({
        text1: 'There was an error while verifying your identity.',
        text2: 'Please try again soon.',
        type: EnumMessageType.ERROR
      })
    }
  }

  const handleDownload = async () => {
    setShowLoadingModal(true)

    if (!localPdfBlob || localPdfBlob) {
      let pdfBlob: Blob | undefined

      try {
        pdfBlob = (await getPDF())
        if (pdfBlob) {
          setLocalPdfBlob(pdfBlob)
          downloadPdfBlob(pdfBlob)
          setShowLoadingModal(false)
        } else {
          openMessage({ type: EnumMessageType.ERROR, text1: 'PDF not found. Please try again in a few minutes.' })
          setShowLoadingModal(false)
        }
      } catch (e) {
        openMessage({
          type: EnumMessageType.ERROR,
          text1: 'Failed to generate PDF',
          text2: 'Please try again in a few minutes'
        })
        setShowLoadingModal(false)
      }
    } else {
      downloadPdfBlob(localPdfBlob)
      setShowLoadingModal(false)
    }
  }

  const getPDF = async () => {
    if (transaction?.document?.link) {
      try {
        // return (await getTransactionPDFData(transaction))
        const res = await retrieveTransactionPdf(transaction.transactionId)
        return res.data as Blob
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error while loading pdf: ', e)
      }
    }
    return undefined
  }

  const emailPDF = async () => {
    if (currentUser?.email) {
      await handleEmailPDF()
    } else {
      setShowEmailModal(true)
    }
  }

  const handleSubmitEmailForm = async ({ email }: { email: string}) => {
    setShowEmailModal(false)
    await handleEmailPDF(email)
  }

  const handleCloseReportModal = () => {
    setShowReportIssueModal(false)
    if (onRefresh) {
      onRefresh()
    }
  }

  const handleEmailPDF = async (customEmail?: string) => {
    setIsEmailing(true)
    if (
      transaction?.transactionId
      && (!!customEmail || currentUser?.email)
    ) {
      // Send the email request to the backend
      try {
        await (
          await authApiAxiosWithRefresh()
        ).post('/transaction/email', {
          transactionId: transaction.transactionId,
          email: customEmail ?? currentUser?.email
        })
        openMessage({
          type: EnumMessageType.INFO,
          text1: 'Email sent',
          text2: 'Please check your account email in a few minutes.'
        })
      } catch (err: any) {
        openMessage({
          type: EnumMessageType.ERROR,
          text1: 'Ran into an issue when trying to email the PDF!',
          text2: err.message
        })
      }
    }
    setIsEmailing(false)
  }

  const viewPDF = async () => {
    setShowLoadingModal(true)
    if (!localPdfBlob) {
      let pdfBlob: Blob | undefined
      try {
        pdfBlob = (await getPDF())
      } catch (e) {
        openMessage({
          type: EnumMessageType.ERROR,
          text1: 'Failed to generate PDF',
          text2: 'Please try again in a few minutes'
        })
        setShowLoadingModal(false)
        return
      }
      if (pdfBlob) {
        setLocalPdfBlob(pdfBlob)
        setShowLoadingModal(false)
        setPdfModalVisible(true)
      } else {
        openMessage({ type: EnumMessageType.ERROR, text1: 'PDF not found. Please try again in a few minutes.' })
        setShowLoadingModal(false)
      }
    } else {
      setShowLoadingModal(false)
      setPdfModalVisible(true)
    }
  }

  const downloadPdfBlob = (pdfBlob: Blob) => {
    if (pdfBlob && transaction) {
      const blobURL = window.URL.createObjectURL(pdfBlob)
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobURL
      tempLink.setAttribute('download', `kuaay-${transaction.transactionId}.pdf`)
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL)
      }, 100)
    }
  }

  const handleClickGetShippingQuote = () => {
    resetNewShipmentState()
    navigate(`${EnumRoute.NewShipping}?transactionId=${transaction?.transactionId}`)
  }

  const handleClickViewShipmentDetails = () => {
    navigate(`${EnumRoute.ShippingAndTracking}/${transaction?.shipment?.shipmentId}`)
  }

  const handleClickCompletePaymentTransfer = async () => {
    navigate(`/payment-transfer/${transaction?.transactionId}`)
  }

  const shipmentGroup = useMemo(() => (
    <ActionGroup label={'Shipping'}>
      {
        transaction && !transaction?.shipment?.shipmentId && (
          <Button
            type="primary"
            color="primary"
            onClick={handleClickGetShippingQuote}
          >
            Get a shipping quote
          </Button>
        )
      }
      {
        transaction && transaction?.shipment?.shipmentId && (
          <Button
            type="primary"
            color="info"
            onClick={handleClickViewShipmentDetails}
          >
            View shipping details
          </Button>
        )
      }
    </ActionGroup>
  ), [transaction, transaction?.shipment?.shipmentId])

  const documentsGroup = useMemo(() => {
    return transaction.documentEnabled ? (
      <ActionGroup label={'Documents'}>
        {
          !!paymentTransfer?.enabled && !isTransferred ? (
            <div>The documents will be processed after we go through the vehicle payment flow.</div>
          )
            : transaction?.document?.link?.length ? (
              <>
                <Button
                  type="primary"
                  color="default"
                  onClick={handleDownload}
                >
                  Download PDF
                </Button>
                <Button
                  type="primary"
                  color="secondary"
                  disabled={isEmailing}
                  onClick={emailPDF}
                >
                  { isEmailing ? 'Sending PDF' : 'Email PDF ' }
                  (
                  { currentUser?.email ?? 'Unknown email' }
                  )
                </Button>
                <Button
                  type="primary"
                  color="primary"
                  onClick={viewPDF}
                >
                  View PDF
                </Button>
              </>
            ) : (
              <div>We are preparing your documents. You will receive it within 48 hours.</div>
            )
        }
      </ActionGroup>
    ) : undefined
  }, [currentUser, isEmailing, transaction, isTransferred])

  const renderPaymentProtectionInfo = useCallback(() => {
    return (
      paymentTransfer?.protectionEnabled ? (
        <li>Payment Protection is enabled.</li>
      ) : (
        <li>Payment Protection is NOT enabled.</li>
      )
    )
  }, [paymentTransfer])

  const renderDetailsPaymentProtectionInfo = useCallback(() => {
    const knownIssues = paymentTransfer?.seller?.knownIssuesDisclosure?.split('\n')
    return (
      <>
        {
          paymentTransfer?.protectionEnabled ? (
            <div>Payment Protection is enabled.</div>
          ) : (
            <div>Payment Protection is NOT enabled.</div>
          )
        }
        {
          paymentTransfer?.protectionEnabled && !!paymentTransfer?.seller?.knownIssuesDisclosure && !!knownIssues?.length && (
            <>
              <Spacer space={24} />
              <div className="payment-transfer-history-modal__label">
                Known Issues disclosure:
              </div>
              <ul className="payment-transfer-history-modal__content">
                {
                  knownIssues.map((it, idx) => (
                    <li>
                      {it}
                    </li>
                  ))
                }
              </ul>
            </>
          )
        }
      </>
    )
  }, [paymentTransfer])

  const paymentTransferHistoriesSection = useMemo(() => (
    <div className="transaction-detail-actions__group__payment-transfer-history">
      <ul>
        {renderPaymentProtectionInfo()}
        {!!paymentTransferHistories?.length && (
          <li>
            {ptHistoryMapping[paymentTransferHistories[paymentTransferHistories.length - 1].status]?.[0]}
          </li>
        )}
      </ul>
      <Button
        className="transaction-detail-actions__group__payment-transfer-history__details-btn"
        type="primary"
        color="default"
        onClick={() => { setShowPTHistoryModal(true) }}
      >
        Details
      </Button>
    </div>
  ), [transaction, userRole])

  const renderPaymentTransferHistoriesContent = useCallback(() => (
    <>
      <Spacer space={24} />
      {renderDetailsPaymentProtectionInfo()}
      <Spacer space={24} />
      <div className="payment-transfer-history-modal__label">
        Payment Transfer history:
      </div>
      <div className="payment-transfer-history-modal__content">
        <ul>
          {
            !paymentTransferHistories?.length ? (
              <div>None</div>
            ) : (
              paymentTransferHistories?.map((it) => ptHistoryMapping[it.status]?.map((history) => (
                <li>
                  {`${it.createdAt?.toString()?.split('T')?.[0]}: ${history}`}
                </li>
              )))
            )
          }
        </ul>
      </div>
    </>
  ), [transaction, userRole])

  const renderPaymentTransferGroup = () => (
    <ActionGroup label={'Payment Transfer'}>
      <TransactionReportIssueModal
        open={showReportIssueModal}
        onClose={handleCloseReportModal}
        userRole={userRole}
        transactionId={transaction.transactionId}
      />
      <ConfirmModal
        open={showCompletePaymentTransferModal}
        width={confirmModalWidth}
        cancelColor="default"
        okText="Complete Verification"
        onOk={handleCompleteTransaction}
        onCancel={() => { setShowCompletePaymentTransferModal(false) }}
        closable
      >
        <div>
          <Text>
            Once you have verified the vehicle condition, you can mark the vehicle condition as “verified”
            which will finalize the transaction and send the funds to the seller.
          </Text>
        </div>
        <Spacer space={24} />
        <div>
          <Text>
            Would you like to complete vehicle verification?
          </Text>
        </div>
      </ConfirmModal>
      <Modal
        className="payment-transfer-history-modal"
        title="Payment Transfer details"
        centered
        width={isMobileDevice ? windowWidth - 48 : 800}
        footer={null}
        open={showPTHistoryModal}
        closable={true}
        onCancel={() => setShowPTHistoryModal(false)}
      >
        {renderPaymentTransferHistoriesContent()}
      </Modal>
      {paymentTransferHistoriesSection}

      {userRole === EnumUserRole.Buyer ? (
        renderBuyerPaymentTransfer()
      ) : (
        renderSellerPaymentTransfer()
      )}
    </ActionGroup>
  )

  return (
    <>
      { showLoadingModal && <LoadingModal title="Loading PDF..." /> }
      <Modal
        className="email-pdf-modal"
        open={showEmailModal}
        centered
        width={348}
        closable={false}
        footer={null}
      >
        <div className="email-pdf-modal__title">Enter email PDF should be sent to:</div>
        <Form
          className="email-pdf-modal__form"
          initialValues={{ email: '' }}
          onFinish={handleSubmitEmailForm}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input Email to send.' }]}
          >
            <Input
              className="kuaay-input-v2"
              placeholder="Email"
              autoCapitalize="none"
              inputMode="email"
            />
          </Form.Item>
          <div className="email-pdf-modal__form-actions">
            <Button
              type="primary"
              color="danger"
              onClick={() => setShowEmailModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              color="primary"
              disabled={isEmailing}
              htmlType="submit"
            >
              Send
            </Button>
          </div>
        </Form>
      </Modal>
      {
        transaction && (
          <>
            <TransactionFeeInfoModal
              open={feeModalVisible}
              transaction={transaction}
              onClose={() => setFeeModalVisible(false)}
            />
            {
              localPdfBlob && (
                <TransactionPdfModal
                  open={pdfModalVisible}
                  pdfBlob={localPdfBlob}
                  onClose={() => setPdfModalVisible(false)}
                />
              )
            }
          </>
        )
      }
      <Row className="transaction-detail-actions">
        <Col
          span={24}
          className="transaction-detail-actions__content"
        >
          {
            transaction.documentEnabled && (
              <div
                className="tap-to-see-more"
                onClick={() => setFeeModalVisible(true)}
              >
                Tap here to see payment and mailing information
              </div>
            )
          }
          {/* Share function is not fully supported on browser */}
          {/* <Button type="primary" color="info" block onClick={handleShare}> */}
          {/*  Share */}
          {/* </Button> */}

          {
            paymentTransfer?.enabled && renderPaymentTransferGroup()
          }
          {/* DOCUMENT: */}
          {
            documentsGroup
          }
          {
            shipmentGroup
          }
        </Col>
      </Row>
    </>
  )
}
