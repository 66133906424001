import React, {
  memo,
  useEffect, useMemo, useRef, useState
} from 'react'
import {
  Col, Form, FormInstance, Row, Select
} from 'antd'

import { AutoComplete } from 'src/components/AutoComplete'
import { Input } from 'src/components/Input'
import { Option } from 'src/components/Select'
import { useStateRef } from 'src/hooks/useStateRef'
import {
  CreateShipmentVehicle, OperableType, ShipmentVehicleType
} from 'src/types/shipments'
import { AVAILABLE_VEHICLE_YEARS, SHIPMENT_OPERABLE_TYPES, YEAR_REGEX } from 'src/utils/constants'
import { getAvailableVehicles, parseVehicleFromVin } from 'src/utils/shipment'

import './VehicleForm.scss'

interface VehicleFormProps {
  form: FormInstance
  vehicleInfo: CreateShipmentVehicle | null
  idx: number
  onChange?: (vehicleInfo: CreateShipmentVehicle) => void
  loading: boolean
}

const availableYearOptions = AVAILABLE_VEHICLE_YEARS.map((it) => ({ value: it }))
const defaultVehicleTypes = [
  ShipmentVehicleType.Car,
  ShipmentVehicleType.MiniVan,
  ShipmentVehicleType.Motorcycle,
  ShipmentVehicleType.Suv,
  ShipmentVehicleType.Van
]

const VehicleFormComp = ({
  form, vehicleInfo, idx, onChange, loading
}: VehicleFormProps) => {
  const prevVehicleInfo = useRef<CreateShipmentVehicle|null>(null)
  const [vin, setVin, vinRef] = useStateRef<string|null>(null)
  const [year, setYear, yearRef] = useStateRef<number|null>(null)
  const [make, setMake, makeRef] = useStateRef<string|null>(null)
  const [bodyType, setBodyType, vehicleTypeRef] = useStateRef<string|null>(null)
  const [operable, setOperable, operableRef] = useStateRef<OperableType|null>(null)
  const [model, setModel, modelRef] = useStateRef<string|null>(null)
  const [searchMake, setSearchMake] = useState<string|null>(null)
  const [searchModel, setSearchModel] = useState<string|null>(null)
  const [loadingVehicles, setLoadingVehicles] = useState(false)
  const [availableMakes, setAvailableMakes] = useState<string[]>([])
  const [availableModels, setAvailableModels] = useState<string[]>([])
  const [availableVehicleTypes, setAvailableVehicleTypes] = useState<string[]>([])
  const makeOptions = useMemo(() => {
    const res = availableMakes.map((it) => ({ value: it, label: it }))
    if (!!searchMake && !availableMakes.includes(searchMake)) {
      res.unshift({ label: searchMake, value: searchMake })
    }
    return res
  }, [availableMakes, searchMake])
  const modelOptions = useMemo(() => {
    const res = availableModels.map((it) => ({ value: it, label: it }))
    if (!!searchModel && !availableModels.includes(searchModel)) {
      res.unshift({ label: searchModel, value: searchModel })
    }
    return res
  }, [availableModels, searchModel])
  const vehicleTypeOptions = useMemo(() => availableVehicleTypes.map((it) => ({ value: it, label: it })), [availableVehicleTypes])

  const vinField = `vin${idx}`
  const yearField = `year${idx}`
  const makeField = `make${idx}`
  const modelField = `model${idx}`
  const vehicleTypeField = `vehicleType${idx}`
  const operableField = `operable${idx}`

  useEffect(() => {
    if (!!vehicleInfo?.vin
      && prevVehicleInfo.current?.vin !== vehicleInfo?.vin
      && !vehicleInfo.year && !vehicleInfo?.make && !vehicleInfo?.model && !vehicleInfo?.bodyType) {
      setVehicleState({ vin: vehicleInfo.vin, operable: vehicleInfo.operable })
      handleChangeVin(vehicleInfo.vin)
    } else if (vehicleInfo) {
      setVehicleState({
        ...vehicleInfo
      })

      const handleNewVehicleInfo = async () => {
        if (vehicleInfo.year && !availableMakes.length) {
          await loadAvailableMakes(vehicleInfo.year)
          if (vehicleInfo.make && !availableModels.length) {
            await loadAvailableModels(vehicleInfo.year, vehicleInfo.make)
            if (vehicleInfo.bodyType && !availableVehicleTypes.length) {
              await loadAvailableVehicleTypes(vehicleInfo.year, vehicleInfo.make, vehicleInfo.model)
            }
          }
        }
      }
      handleNewVehicleInfo()
    }
    prevVehicleInfo.current = vehicleInfo
  }, [vehicleInfo])

  const setVehicleState = (
    data: {
      vin?: string | null
      year?: number | null
      make?: string | null
      model?: string | null
      bodyType?: ShipmentVehicleType | null
      operable?: OperableType | null
    },
    emitChange = false
  ) => {
    const {
      vin: newVin, year: newYear, make: newMake, model: newModel, bodyType: newVehicleType, operable: newOperable
    } = data
    let forceChange = emitChange
    const emittedData = {
      vin: vinRef.current,
      model: modelRef.current,
      make: makeRef.current,
      bodyType: vehicleTypeRef.current,
      operable: operableRef.current,
      year: yearRef.current
    } as CreateShipmentVehicle
    if (newVin !== undefined) {
      emittedData.vin = newVin
      setVin(newVin)
      form.setFieldValue(vinField, newVin)
    }
    if (newYear !== undefined) {
      emittedData.year = newYear
      setYear(newYear)
      form.setFieldValue(yearField, newYear)
    }
    if (newMake !== undefined) {
      emittedData.make = newMake
      setMake(newMake)
      form.setFieldValue(makeField, newMake)
    }
    if (newModel !== undefined) {
      emittedData.model = newModel
      setModel(newModel)
      form.setFieldValue(modelField, newModel)
    }
    if (newVehicleType !== undefined) {
      let newVehicleTypeTmp = newVehicleType
      if (!!newVehicleType && !defaultVehicleTypes.includes(newVehicleType)) {
        [newVehicleTypeTmp] = defaultVehicleTypes
        forceChange = true
      }
      emittedData.bodyType = newVehicleTypeTmp
      setBodyType(newVehicleTypeTmp)
      form.setFieldValue(vehicleTypeField, newVehicleTypeTmp)
    }
    if (newOperable !== undefined) {
      emittedData.operable = newOperable
      setOperable(newOperable)
      form.setFieldValue(operableField, newOperable)
    }
    if (forceChange) {
      // form.validateFields([vinField, yearField, makeField, modelField, vehicleTypeField, operableField])
      onChange?.(emittedData)
    }
  }

  const handleChangeVin = async (text: string) => {
    if (text.slice(0, 1).toLowerCase() === 'i') {
      text = text.slice(1)
    }

    if (text.length === 17) {
      const vinVehicleInfo = await parseVehicleFromVin(text)

      if (vinVehicleInfo) {
        setVehicleState({
          vin: text,
          year: vinVehicleInfo.year ?? null,
          make: vinVehicleInfo.make ?? null,
          model: vinVehicleInfo.model ?? null,
          bodyType: vinVehicleInfo.bodyType ?? null
        }, true)
        if (vinVehicleInfo.year) {
          await loadAvailableMakes(vinVehicleInfo.year)
          if (vinVehicleInfo.make) {
            await loadAvailableModels(vinVehicleInfo.year, vinVehicleInfo.make)
          }
        }
      } else {
        setVehicleState({
          vin: text
        }, true)
      }
    } else {
      setVehicleState({
        vin: text
      }, true)
    }
  }

  const yearCustomValidationRule = () => ({
    validator(_: any, value: any) {
      if (!value || `${value}`.trim() === '') {
        return Promise.reject(new Error('Year is required'))
      }
      if (
        YEAR_REGEX.test(value)
        && value <= new Date().getUTCFullYear()
        && value > 1886
      ) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Year is incorrect'))
    }
  })

  const loadAvailableMakes = async (year: number) => {
    const availableVehicles = await getAvailableVehicles({ year })
    const makes: string[] = []
    if (availableVehicles.length) {
      availableVehicles.forEach((v) => {
        if (v.make && !makes.includes(v.make)) {
          makes.push(v.make)
        }
      })
    }
    setAvailableMakes(makes)
  }

  const handleChangeYear = async (newYear: number) => {
    if (newYear === year) {
      return
    }
    setYear(newYear)
    setLoadingVehicles(true)
    await loadAvailableMakes(newYear)
    setAvailableModels([])
    setAvailableVehicleTypes([])
    setVehicleState({
      year: newYear, make: null, model: null, bodyType: null
    }, true)
    setLoadingVehicles(false)
  }

  const findSimilarMake = (findStr: string) => {
    if (!findStr) {
      return ''
    }
    return availableMakes.find((it) => it.toLowerCase() === findStr.toLowerCase()) ?? findStr
  }

  const handleChangeMake = (newMake: string) => {
    const formattedMake = findSimilarMake(newMake)
    setAvailableModels([])
    setSearchModel('')
    setVehicleState({
      make: formattedMake
    }, !availableMakes.includes(formattedMake))
  }

  const loadAvailableModels = async (year: number, make: string) => {
    const models: string[] = []
    const availableVehicles = await getAvailableVehicles({ year, make })
    if (availableVehicles.length) {
      availableVehicles.forEach((v) => {
        if (v.model && !models.includes(v.model)) {
          models.push(v.model)
        }
      })
    }
    setAvailableModels(models)
  }

  const handleSelectMake = async (newMake: string) => {
    if (newMake === make) {
      return
    }
    setLoadingVehicles(true)
    if (availableMakes.includes(newMake) && year) {
      await loadAvailableModels(year, newMake)
    } else {
      setAvailableModels([])
    }

    setVehicleState({ make: newMake, model: null, bodyType: null }, true)
    setLoadingVehicles(false)
  }

  const handleSearchMake = (searchStr: string) => {
    setSearchMake(searchStr)
  }

  const handleSearchModel = (searchStr: string) => {
    setSearchModel(searchStr)
  }

  const onModelDropdown = () => {
    if (make && availableMakes.includes(make) && !availableModels.length) {
      handleSelectMake(make)
    }
  }

  const onVehicleTypeDropdown = () => {
    if (!availableVehicleTypes.length) {
      if (model && availableModels.includes(model)) {
        handleSelectModel(model)
      } else {
        setAvailableVehicleTypes(defaultVehicleTypes)
      }
    }
  }

  const findSimilarModel = (findStr: string) => {
    if (!findStr) {
      return findStr
    }
    return availableModels.find((it) => it.toLowerCase() === findStr.toLowerCase()) ?? findStr
  }

  const handleChangeModel = (newModel: string) => {
    const formattedModel = findSimilarModel(newModel)
    setSearchModel(formattedModel)
    // setModel(formattedModel)
    // form.setFieldValue(modelField, formattedModel)
    setAvailableVehicleTypes([])
    setVehicleState({ model: formattedModel }, !availableModels.includes(formattedModel))
  }

  const loadAvailableVehicleTypes = async (year: number | null, make: string | null, model: string | null) => {
    if (!year || !make || !model) {
      return []
    }
    const vehicleTypes: ShipmentVehicleType[] = []
    const availableVehicles = await getAvailableVehicles({ year, make, model })
    if (availableVehicles.length) {
      availableVehicles.forEach((v) => {
        if (v.bodyType && !vehicleTypes.includes(v.bodyType)) {
          vehicleTypes.push(v.bodyType)
        }
      })
    }
    if (vehicleTypes.length) {
      setAvailableVehicleTypes(vehicleTypes)
    } else {
      setAvailableVehicleTypes(defaultVehicleTypes)
    }
    return vehicleTypes
  }

  const handleSelectModel = async (newModel: string) => {
    if (newModel === model && availableVehicleTypes.length) {
      return
    }
    setModel(newModel)
    setLoadingVehicles(true)
    let vehicleTypes: ShipmentVehicleType[] = []
    if (availableModels.includes(newModel)) {
      vehicleTypes = await loadAvailableVehicleTypes(year, make, newModel)
    } else {
      setAvailableVehicleTypes(defaultVehicleTypes)
    }

    setVehicleState({ bodyType: vehicleTypes[0] ?? null }, true)
    setLoadingVehicles(false)
  }

  const handleChangeVehicleType = (newVehType: ShipmentVehicleType) => {
    const newValue = newVehType === undefined ? null : newVehType
    setVehicleState({ bodyType: newValue }, true)
  }

  const handleChangeOperable = (newOp: OperableType) => {
    setVehicleState({ operable: newOp }, true)
  }

  return (
    <div className="vehicle-form">
      <Row>
        <Col xs={24} md={12}>
          <Form.Item
            name={vinField}
            rules={[{ required: true, message: 'VIN is required' }]}
            initialValue={vin}
          >
            <Input
              placeholder="VIN"
              disabled={loading}
              onChange={(e) => {
                handleChangeVin(e.target.value)
              }}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={{ offset: 1, span: 11 }}>

          <Form.Item
            name={yearField}
            rules={[yearCustomValidationRule()]}
            initialValue={year}
          >
            <Select
              className="kuaay-select vehicle-form__select"
              placeholder="Year"
              disabled={loading}
              options={availableYearOptions}
              onChange={handleChangeYear}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12}>

          <Form.Item
            name={makeField}
            initialValue={make}
            rules={[{ required: true, message: 'Make is required' }]}
          >
            <AutoComplete
              allowClear
              placeholder="Make"
              disabled={loading || loadingVehicles || !year}
              options={makeOptions}
              onSelect={handleSelectMake}
              onChange={handleChangeMake}
              onSearch={handleSearchMake}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={{ offset: 1, span: 11 }}>

          <Form.Item
            name={modelField}
            initialValue={make}
            rules={[{ required: true, message: 'Model is required' }]}
          >
            <AutoComplete
              allowClear
              placeholder="Model"
              disabled={loading || loadingVehicles || !year || !make}
              options={modelOptions}
              onSelect={handleSelectModel}
              onChange={handleChangeModel}
              onSearch={handleSearchModel}
              onFocus={onModelDropdown}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12}>

          <Form.Item
            name={vehicleTypeField}
            rules={[{ required: true, message: 'Vehicle Type is required' }]}
            initialValue={
              bodyType
            }
          >
            <Select
              className="kuaay-select vehicle-form__select"
              filterOption={false}
              placeholder="Vehicle type"
              disabled={loading || loadingVehicles || !model}
              options={vehicleTypeOptions}
              onChange={handleChangeVehicleType}
              onFocus={onVehicleTypeDropdown}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={{ offset: 1, span: 11 }}>
          <Form.Item
            name={operableField}
            rules={[{ required: true, message: 'Operable is required' }]}
            initialValue={operable}
          >
            <Select
              className="kuaay-select vehicle-form__select"
              disabled={loading}
              placeholder="Operable"
              onChange={handleChangeOperable}
            >
              {SHIPMENT_OPERABLE_TYPES.map((operableType) => (
                <Option
                  key={`operableType.${operableType}`}
                  value={operableType}
                >
                  {`${operableType.charAt(0)}${operableType.slice(1).toLowerCase()}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

    </div>
  )
}

export const VehicleForm = memo(VehicleFormComp)
