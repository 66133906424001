import {
  useContext, useEffect, useRef, useState
} from 'react'
import { Col, Row, Typography } from 'antd'

import { Spacer } from 'src/components/Spacer'
import { TransactionDetailActions } from 'src/components/Transaction'
import { useNewShipment } from 'src/hooks/useNewShipment'
import { EnumNewShipmentStep } from 'src/router/types'
import { store } from 'src/store/store'
import { TransactionInfo, TransactionInfoRaw } from 'src/types/transactions'
import { DOCUMENT_PREPARATION_HOURS } from 'src/utils/constants'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { transactionRawToTransactionInfo } from 'src/utils/transaction'
import { EnumUserType } from 'src/utils/types'

import './style.scss'

interface NewTransactionOrderConfirmationProps {
  userType: EnumUserType
}

export default function NewTransactionOrderConfirmation({ userType }: NewTransactionOrderConfirmationProps) {
  const globalStore = useContext(store)
  const userRole = transactionUserInfo(userType, globalStore).role
  const { resetNewShipmentState, navigateToStep } = useNewShipment()
  const [transaction, setTransaction] = useState<TransactionInfo | null>(null)
  const countdownRef = useRef<number>(5)
  const [countdownNum, setCountdownNum] = useState<number>(5)
  const timeoutRef = useRef<number|null>(null)
  const { documentEnabled, paymentTransfer } = globalStore.state.createTransaction

  useEffect(() => {
    if (transaction?.transactionId && transaction.shipment?.enabled) {
      const tmpTimeoutId = setInterval(() => {
        if (countdownRef.current <= 0) {
          resetNewShipmentState()
          navigateToStep({
            step: EnumNewShipmentStep.MainInfo,
            transactionId: globalStore.state.createTransaction.transactionId
          })
          if (timeoutRef.current) {
            clearInterval(timeoutRef.current)
          }
        } else {
          countdownRef.current -= 1
          setCountdownNum(countdownRef.current)
        }
      }, 1000)
      timeoutRef.current = tmpTimeoutId as unknown as number
    }

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current)
      }
    }
  }, [transaction])

  useEffect(() => {
    if (globalStore.state.createTransaction.transactionId) {
      authApiAxiosWithRefresh()
        .then((axiosInst) => axiosInst.get(
          `/transaction/retrieve/${globalStore.state.createTransaction.transactionId}`
        ))
        .then((res) => {
          setTransaction(transactionRawToTransactionInfo(res.data as TransactionInfoRaw))
        })
    }
  }, [globalStore.state.createTransaction.transactionId])

  return (
    <Row className="new-transaction-order-confirmation">
      <Col span={22} offset={1} className="new-transaction-order-confirmation__content-wrapper">
        <Spacer space={32} />
        {
          documentEnabled && (
            <>
              {
                paymentTransfer.enabled ? (
                  <Typography.Title level={4} className="new-transaction-order-confirmation__title">
                    The documents will be processed after we go through the vehicle payment flow.
                  </Typography.Title>
                ) : (
                  <>
                    <Typography.Title level={4} className="new-transaction-order-confirmation__title">
                      Your documents are being prepared
                    </Typography.Title>
                    <Spacer space={32} />
                    <div className="new-transaction-order-confirmation__description">
                      You will receive email confirmation in
                      {
                        ` ${DOCUMENT_PREPARATION_HOURS} hours`
                      }
                    </div>
                  </>
                )
              }
              <span />
            </>
          )
        }

        {
          !!transaction?.transactionId && transaction.shipment?.enabled && (
            <>
              <Spacer space={12} />
              <div className="new-transaction-order-confirmation__redirect-note">
                You will be redirected to shipping in
                {' '}
                { countdownNum }
                {' '}
                seconds...
              </div>
              <Spacer space={12} />
            </>
          )
        }
        {transaction && userRole && (
          <TransactionDetailActions transaction={transaction} userRole={userRole} />
        )}
        <Spacer space={32} />
      </Col>
    </Row>
  )
}
