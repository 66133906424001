import React, { ChangeEvent, useContext } from 'react'
import {
  Col,
  Form,
  Row,
  Typography
} from 'antd'
import classNames from 'classnames'

import { Button } from 'src/components/Button'
import { TextArea } from 'src/components/Input'
import { Spacer } from 'src/components/Spacer'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { EnumShareStep } from 'src/utils/types'

import './styles.scss'

const { Text } = Typography

export default function NewTransactionKnownIssuesDisclosure() {
  const {
    navigateToStep, shareStep, shareTransactionId, userType, isSecondary
  } = useNewTransaction()
  const globalStore = useContext(store)
  const [form] = Form.useForm()
  const { openMessage } = useMessage()

  const {
    paymentTransfer,
    documentEnabled
  } = globalStore.state.createTransaction

  const {
    knownIssuesDisclosure
  } = paymentTransfer.seller

  const handleSubmit = async () => {
    if (shareStep === EnumShareStep.UpdateKnownIssues || (shareStep === EnumShareStep.Shared && !documentEnabled)) {
      try {
        const res = await (
          await authApiAxiosWithRefresh()
        )
          .post('/transaction/share/continue', {
            transactionInfo: globalStore.state.createTransaction,
            shareTransactionId
          })

        navigateToStep({
          step: EnumNewTransactionParams.ShareConfirmation,
          userType,
          shareUrl: encodeURIComponent(res.data.shareUrl)
        })
      } catch (e) {
        openMessage(
          {
            type: EnumMessageType.ERROR,
            text1: 'There was an error while storing your transaction',
            text2: 'Please try again soon.'
          }
        )
      }
    } else {
      if (documentEnabled) {
        navigateToStep({ step: EnumNewTransactionParams.SignatureOdometer })
      } else {
        if (isSecondary) {
          navigateToStep({ step: EnumNewTransactionParams.Complete })
        } else {
          navigateToStep({ step: EnumNewTransactionParams.NextUser })
        }
      }
    }
  }

  const handleClickContinue = () => {
    form.submit()
  }

  const handleChangeDisclosure = (e: ChangeEvent<HTMLTextAreaElement>) => {
    globalStore.dispatch({
      type: 'UPDATE_KNOWN_ISSUES_DISCLOSURE',
      disclosure: e.target.value
    })
  }

  return (
    <Row className={classNames('known-issues-disclosure')}>
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <Spacer space={24} />
        <Form
          className="known-issues-disclosure__form kuaay-form"
          onFinish={handleSubmit}
          form={form}
        >
          {
            shareStep === EnumShareStep.UpdateKnownIssues && (
              <>
                <div className="text--error">
                  The buyer has declined your known issues disclosure with following message:
                </div>
                <Spacer space={6} />
                <div className="known-issues-disclosure__buyer-message text--error" style={{ padding: '0 16px', fontStyle: 'italic' }}>
                  {
                    paymentTransfer.buyer?.knownIssuesAgreement?.rejectReasons
                  }
                </div>
                <Spacer space={24} />
              </>
            )
          }
          <Text>
            Please enter, in detail, all known issues to disclose to the buyer.
            Any issues not mentioned that appear during the vehicle delivery will be able to be disputed by the buyer.
            Shipping carrier-related issues will be covered by Pacer.
          </Text>
          <Spacer space={24} />
          <Form.Item
            name="message"
            rules={[{ required: true, message: 'Please input all known issues.' }]}
            initialValue={knownIssuesDisclosure}
          >
            <TextArea
              placeholder="Describe all known issues here (one line per issue):"
              rows={10}
              onChange={handleChangeDisclosure}
            />
          </Form.Item>
        </Form>
        <Spacer className="flex-grow-1" space={24} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="primary"
          onClick={handleClickContinue}
        >
          Continue
        </Button>
      </Col>
    </Row>
  )
}
