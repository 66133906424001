import axios from 'axios'
import qs from 'qs'

import { ShipmentAvailableVehicle, ShipmentInfo, ShipmentStatus } from 'src/types/shipments'

import { stringToDate } from './common'
import { API_URL } from './constants'

export const shipmentRawToShipmentInfo = (shipmentRaw: ShipmentInfo) => ({
  ...shipmentRaw,
  creationDate: stringToDate(shipmentRaw.creationDate),
  shippingDate: stringToDate(shipmentRaw.shippingDate),
  lastStatusUpdatedDate: stringToDate(shipmentRaw.lastStatusUpdatedDate)
})

export const getShipmentStatusDescription = (shipment: ShipmentInfo) => {
  switch (shipment.status) {
  case ShipmentStatus.CREATED:
    return 'Carrier assigned'
  case ShipmentStatus.DRIVER_ASSIGNED:
    return 'Driver assigned'
  case ShipmentStatus.PICKED_UP:
    return 'Load picked up'
  case ShipmentStatus.COMPLETED:
    return 'Completed'
  case ShipmentStatus.CANCELED:
    return 'Load canceled'
  default:
    return 'Pending'
  }
}

export const getAvailableVehicles = async (params: {year?: number; make?: string; model?: string}): Promise<ShipmentAvailableVehicle[]> => {
  try {
    const res = await axios.get(`${API_URL}/shipment/public/vehicles?${qs.stringify(params)}`)
    return res.data ?? [] as ShipmentAvailableVehicle[]
  } catch (e) {
    return []
  }
}

export const parseVehicleFromVin = async (vin: string): Promise<ShipmentAvailableVehicle|null> => {
  try {
    const res = await axios.get(`${API_URL}/shipment/public/vehicles?vin=${vin}`)
    return res.data as ShipmentAvailableVehicle
  } catch (e) {
    return null
  }
}

export const getQuoteById = async (quoteId: string, site: string, requestId: string): Promise<ShipmentInfo|null> => {
  try {
    const res = await axios.get(`${API_URL}/shipment/public/retrieve/${quoteId}?site=${site}&requestId=${requestId}`)
    const data = res.data as ShipmentInfo
    return {
      ...data,
      shippingDate: new Date(data.shippingDate)
    }
  } catch (e) {
    return null
  }
}
