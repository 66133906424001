import React from 'react'
import {
  AutoComplete as AntAutoComplete, AutoCompleteProps
} from 'antd'
import classNames from 'classnames'

import './AutoComplete.scss'

export default function AutoComplete(props: AutoCompleteProps) {
  const { className, ...restProps } = props
  return (
    <AntAutoComplete
      filterOption={(inputValue, option: any) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
      className={classNames('kuaay-autocomplete', className)}
      {...restProps}
    />
  )
}
