import React, {
  useContext, useEffect, useMemo, useState
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'

import { Button } from 'src/components/Button'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { CheckoutModal } from 'src/components/Modal'
import { Spacer } from 'src/components/Spacer'
import TransactionDetailInfo from 'src/components/Transaction/TransactionDetailInfo'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { store } from 'src/store/store'
import { TransactionInfo, TransactionInfoRaw } from 'src/types/transactions'
import { formatMoney } from 'src/utils/common'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { fetchStripePublicKey } from 'src/utils/stripeHelpers'
import { getUserInfoInTransaction, transactionRawToTransactionInfo } from 'src/utils/transaction'

import './PaymentTransferDetail.scss'

export default function PaymentTransferDetail() {
  const { transactionId } = useParams()
  const { currentUser } = useAuthen()
  const globalStore = useContext(store)
  const navigate = useNavigate()
  const { openMessage } = useMessage()
  const [isLoading, setIsLoading] = useState(false)
  const [transaction, setTransaction] = useState<TransactionInfo|null>(null)
  const [processingPaymentTransfer, setProcessingPaymentTransfer] = useState(false)
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)
  const [stripeClientSecret, setStripeClientSecret] = useState('')
  const [paymentIntentId, setPaymentIntentId] = useState('')
  const [vehiclePrice, setVehiclePrice] = useState(0)
  const [paymentTransferFee, setPaymentTransferFee] = useState(0)
  const [paymentProtectionFee, setPaymentProtectionFee] = useState(0)
  const [total, setTotal] = useState(0)
  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {
    await fetchTransactionDetail()

    setIsLoading(true)
    const stripePublishableKey = await fetchStripePublicKey(true)
    if (stripePublishableKey) {
      globalStore.dispatch({
        type: 'UPDATE_STRIPE_PUBLISHABLE_KEY',
        stripePublishableKey
      })
      await initializePaymentSheet(stripePublishableKey)
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'No publishable stripe key found! Aborting! Payments WILL NOT WORK!'
      )
    }
    setIsLoading(false)
  }

  const fetchTransactionDetail = async () => {
    authApiAxiosWithRefresh()
      .then((axiosInst) => axiosInst.get(
        `/transaction/retrieve/${transactionId}`
      ))
      .then((res) => {
        setTransaction(transactionRawToTransactionInfo(res.data as TransactionInfoRaw))
      })
  }

  const initializePaymentSheet = async (stripePublishableKey: string) => {
    const {
      clientSecret, ephemeralKey, customer, total
    } = await fetchPaymentSheetParams(true)
  }

  const fetchPaymentSheetParams = async (
    retry: boolean = false
  ): Promise<{
    clientSecret: string;
    paymentIntentId: string;
    ephemeralKey: string;
    customer: string;
    vehiclePrice: number;
    paymentTransferFee: number;
    paymentProtectionFee: number;
    total: number;
  }> => {
    let sheetResponse
    try {
      sheetResponse = await (
        await authApiAxiosWithRefresh()
      ).post(
        '/payment/payment-transfer/payment-sheet',
        {
          transactionId
        },
        {
          headers: { 'content-type': 'application/json' }
        }
      )
      const {
        clientSecret, paymentIntentId, ephemeralKey, customer, vehiclePrice, paymentTransferFee, paymentProtectionFee, total
      } = sheetResponse.data
      setStripeClientSecret(clientSecret)
      setPaymentIntentId(paymentIntentId)
      setVehiclePrice(vehiclePrice)
      setPaymentTransferFee(paymentTransferFee)
      setPaymentProtectionFee(paymentProtectionFee)
      setTotal(total)

      return {
        clientSecret,
        paymentIntentId,
        ephemeralKey,
        customer,
        vehiclePrice,
        paymentTransferFee,
        paymentProtectionFee,
        total
      }
    } catch (err) {
      if (retry) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch payment sheet params! RETRYING!', err)
        return fetchPaymentSheetParams(false)
      }
      // eslint-disable-next-line no-console
      console.error('Failed to fetch payment sheet params!', err)
      openMessage({
        type: EnumMessageType.ERROR,
        text1: 'Unable to initialize payment sheet for this transaction.'
      })
      navigateToTransactionDetail()
      return {
        clientSecret: '',
        paymentIntentId: '',
        ephemeralKey: '',
        customer: '',
        vehiclePrice: 0,
        paymentTransferFee: 0,
        paymentProtectionFee: 0,
        total: 0
      }
    }
  }

  const navigateToTransactionDetail = () => {
    setTimeout(() => {
      navigate(`/transactions/${transactionId}`)
    }, 300)
  }

  const transactionUserInfo = useMemo(() => {
    if (currentUser?.uid && transaction) {
      return getUserInfoInTransaction(currentUser.uid, transaction)
    }
    return undefined
  }, [currentUser, transaction])

  const handlePaymentSuccess = async () => {
    setCheckoutModalOpen(false)
    const passedInfo = {
      transactionId: transaction?.transactionId,
      paymentIntentId
    }
    try {
      const res = await (
        await authApiAxiosWithRefresh()
      ).post(`/payment-transfer/${transaction?.transactionId}/paid`, passedInfo)
      const updatedTxn = res.data as TransactionInfo
      setCheckoutModalOpen(false)
      setProcessingPaymentTransfer(false)
      setIsSuccess(true)
      openMessage(
        {
          text1: 'You have completed transferring the funds to Kuaay.',
          type: EnumMessageType.SUCCESS
        }
      )
      navigateToTransactionDetail()
    } catch (err) {
      openMessage(
        {
          text1: 'There was an error while updating payment transfer status.',
          text2: 'Please try again soon',
          type: EnumMessageType.ERROR
        }
      )
    }
  }

  const handlePaymentFailed = () => {}

  const renderFee = (fee: number) => (isLoading ? (
    <LoadingOutlined className="loading-icon" />
  ) : (
    formatMoney(fee)
  ))

  return (
    <>
      <CheckoutModal
        open={checkoutModalOpen}
        amount={total}
        billingDetail={{ email: transactionUserInfo?.email, name: transactionUserInfo?.fullName }}
        clientSecret={stripeClientSecret}
        onClose={() => setCheckoutModalOpen(false)}
        onSuccess={handlePaymentSuccess}
        onFailed={handlePaymentFailed}
      />
      <HeaderLayout title="Payment Transfer" hasGoBack>

        <Row className="payment-transfer-detail-page">
          <Col span={20} offset={2}>
            {transaction && (
              <TransactionDetailInfo transaction={transaction} />
            )}
            <Spacer space={48} />
            <Row>
              <Col span={16}>
                <div className="payment-transfer-detail__label">
                  Vehicle Sale Price:
                </div>
              </Col>
              <Col span={8}>
                <div className="payment-transfer-detail__value">
                  {renderFee(vehiclePrice)}
                </div>
              </Col>
            </Row>

            {/* <Row> */}
            {/*  <Col span={16}> */}
            {/*    <div className="payment-transfer-detail__label"> */}
            {/*      Payment Transfer Fee (1%): */}
            {/*    </div> */}
            {/*  </Col> */}
            {/*  <Col span={8}> */}
            {/*    <div className="payment-transfer-detail__value"> */}
            {/*      {renderFee(paymentTransferFee)} */}
            {/*    </div> */}
            {/*  </Col> */}
            {/* </Row> */}

            {/* {!!paymentProtectionFee && ( */}
            {/*  <Row> */}
            {/*    <Col span={16}> */}
            {/*      <div className="payment-transfer-detail__label"> */}
            {/*        Payment Protection Fee (3%): */}
            {/*      </div> */}
            {/*    </Col> */}
            {/*    <Col span={8}> */}
            {/*      <div className="payment-transfer-detail__value"> */}
            {/*        {renderFee(paymentProtectionFee)} */}
            {/*      </div> */}
            {/*    </Col> */}
            {/*  </Row> */}
            {/* )} */}

            <hr />

            <Row>
              <Col span={16}>
                <div className="payment-transfer-detail__label">
                  Total:
                </div>
              </Col>
              <Col span={8}>
                <div className="payment-transfer-detail__value">
                  {renderFee(total)}
                </div>
              </Col>
            </Row>

            <div className="payment-transfer-detail__btn-container">
              {
                isSuccess ? (
                  <Button
                    className="payment-transfer-detail__checkout-btn"
                    type="primary"
                    color="success"
                  >
                    Succeeded
                  </Button>
                ) : (
                  <Button
                    className="payment-transfer-detail__checkout-btn"
                    type="primary"
                    color="secondary"
                    loading={isLoading || processingPaymentTransfer}
                    disabled={!stripeClientSecret}
                    onClick={() => setCheckoutModalOpen(true)}
                  >
                    {`Checkout ${formatMoney(total)}`}
                  </Button>
                )
              }
            </div>

          </Col>
        </Row>
      </HeaderLayout>
    </>
  )
}
