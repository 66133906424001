import React, { useState } from 'react'

import type { OdometerUnit as OdometerUnitType } from 'src/types/transactions'
import { ODOMETER_TYPES, ODOMETER_TYPES_ABBR } from 'src/utils/constants'

import './styles.scss'

interface OdometerUnitProps {
  onChange?: Function
  initialValue?: OdometerUnitType
}

export default function OdometerUnit({ onChange, initialValue }: OdometerUnitProps) {
  const [currentIndex, setCurrentIndex] = useState(initialValue
    ? ODOMETER_TYPES.indexOf(initialValue)
    : 0)

  const changeCurrentOdo = () => {
    const newCurrentIndex = (currentIndex + 1) % ODOMETER_TYPES.length
    setCurrentIndex(newCurrentIndex)
    onChange?.({ currentIndex: newCurrentIndex, value: ODOMETER_TYPES[newCurrentIndex] })
  }

  return (
    <span
      className="kuaay-odometer-unit"
      onClick={changeCurrentOdo}
    >
      {ODOMETER_TYPES_ABBR[currentIndex]}
    </span>
  )
}
