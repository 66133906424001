import { useMemo } from 'react'
import { createAvatar } from '@dicebear/avatars'
import * as jdenticonStyle from '@dicebear/avatars-jdenticon-sprites'
import classNames from 'classnames'
import CryptoJS from 'crypto-js'

import { DEFAULT_AVATAR_SIZE, DICEBEARS_SALT } from 'src/utils/constants'

import { CustomAvatarProps } from './CustomAvatar.types'

import './customAvatar.scss'

// eslint-disable-next-line react/require-default-props
function CustomAvatar({
  className, token, email, borderRadius, border, size
}: CustomAvatarProps) {
  const hashedSeed = useMemo(() => CryptoJS.SHA256(
    `${token}${DICEBEARS_SALT}${email}`
  ), [email, token])
  const avatar = useMemo(
    () => createAvatar(jdenticonStyle, { seed: hashedSeed.toString() }),
    [hashedSeed]
  )

  const style = useMemo(() => ({
    width: size ?? DEFAULT_AVATAR_SIZE,
    height: size ?? DEFAULT_AVATAR_SIZE,
    borderRadius: `${borderRadius ?? 5}px`,
    borderWidth: border ? '2px' : 0,
    borderColor: 'var(--primary)',
    borderStyle: 'solid'
  }), [border, borderRadius, size])

  return (
    <div style={style} className={classNames('custom-avatar', className)} dangerouslySetInnerHTML={{ __html: avatar }} />
  )
}

export { CustomAvatar }
