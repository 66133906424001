import { AppActions } from 'src/store/rootActions'
import { PendingTransactionInfo } from 'src/types/transactions'

export type PendingTransactionsState = {
  transactions: PendingTransactionInfo[];
};

const initialPendingTransactionsState: PendingTransactionsState = {
  transactions: []
}

const pendingTransactionsReducer = (
  prevState: PendingTransactionsState,
  action: AppActions
): PendingTransactionsState => {
  switch (action.type) {
  case 'RESET_PENDING_TRANSACTIONS_STATE':
    return initialPendingTransactionsState
  case 'UPDATE_PENDING_TRANSACTIONS':
    return {
      ...prevState,
      transactions: action.transactions || prevState.transactions
    }
  default:
    return prevState
  }
}

export {
  pendingTransactionsReducer,
  initialPendingTransactionsState
}
