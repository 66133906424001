import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuthen } from 'src/hooks/useAuthen'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'

export default function KycRefresh() {
  const { isAuthenLoaded } = useAuthen()
  const location = useLocation()

  useEffect(() => {
    const continueKyc = async () => {
      const searchStr = location.search
      const urlParams = new URLSearchParams(searchStr)
      const onboardRes: any = await (
        await authApiAxiosWithRefresh()
      ).post('/kyc/onboard', {
        context: urlParams.get('context') ?? '',
        transactionId: urlParams.get('transactionId') ?? ''
      })
      window.location.href = onboardRes.data.url
    }
    if (isAuthenLoaded) {
      continueKyc()
    }
  }, [location, isAuthenLoaded])

  return (
    <div className="kyc-refresh-page" />
  )
}
