import React, {
  useContext, useEffect, useMemo, useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CreditCardOutlined,
  DownloadOutlined, DownOutlined,
  EditOutlined,
  SettingOutlined,
  UploadOutlined
} from '@ant-design/icons'
import {
  Col, Dropdown, List, MenuProps, Modal,
  Row, Tabs
} from 'antd'

import { Button } from 'src/components/Button'
import { CustomAvatar } from 'src/components/CustomAvatar'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { LoadingModal } from 'src/components/Modal'
import EditInfoModal from 'src/components/Modal/EditInfoModal'
import { Spacer } from 'src/components/Spacer'
import { AnonymousTransactionCard, NoTransactionCard, TransactionCard } from 'src/components/Transaction'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { EnumKycContext, EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'
import { EnumTransactionAction } from 'src/store/types'
import { KycStatus, UserProfile } from 'src/types/users'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { fetchUserProfile } from 'src/utils/userHelpers'

import './account.scss'

export default function Account() {
  const { openMessage } = useMessage()
  const globalStore = useContext(store)
  const navigate = useNavigate()
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(undefined)
  const {
    isAnonymous, email, refreshToken, isAuthenLoaded, signOut, currentUser
  } = useAuthen()
  const [openEditInfoModal, setOpenEditInfoModal] = useState(false)
  const [isVerifyingIdentity, setIsVerifyingIdentity] = useState(false)
  const [showKycDetail, setShowKycDetail] = useState(false)

  const onRefresh = React.useCallback(() => {
    if (isAuthenLoaded) {
      globalStore.dispatch({
        type: EnumTransactionAction.FetchPreviousTransactions
      })
      globalStore.dispatch({
        type: EnumTransactionAction.FetchPendingTransactions,
        store: globalStore
      })
      loadProfile()
    }
  }, [isAuthenLoaded])

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  useEffect(() => {
    const intervalId = setInterval(() => {
      globalStore.dispatch({
        type: EnumTransactionAction.FetchPreviousTransactions
      })
    }, 30 * 60 * 1000)

    return () => clearInterval(intervalId)
  })

  const loadProfile = async () => {
    const profile = await fetchUserProfile()
    setUserProfile(profile)
  }

  const subtitle = useMemo(
    () => (isAuthenLoaded ? (currentUser?.isAnonymous === false ? 'All About You' : 'Sign In/Create Account') : undefined),
    [isAuthenLoaded, currentUser]
  )

  const tabItems = useMemo(() => {
    const generateTransactionCardList = () => (
      <>
        {
          isAnonymous && (
            <AnonymousTransactionCard
              isPending={false}
              transactionCount={globalStore.state.previousTransactions.transactions.length}
            />
          )
        }
        {
          globalStore.state.previousTransactions.transactions.length === 0 && !isAnonymous && (
            <NoTransactionCard isPending={false} />
          )
        }
        {
          globalStore.state.previousTransactions.transactions.length > 0 && (
            <List
              dataSource={
                globalStore.state.previousTransactions.transactions
                // Sort the previous transactions by newest first
                  .sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime())
              }
              renderItem={(item) => (
                <List.Item>
                  <TransactionCard transaction={item} isPending={false} />
                </List.Item>
              )}
            />
          )
        }
      </>
    )
    const generatePendingTransactionCardList = () => (
      isAnonymous ? (
        <AnonymousTransactionCard isPending />
      ) : (
        globalStore.state.pendingTransactions.transactions.length === 0 ? (
          <NoTransactionCard isPending />
        ) : (
          <List
            dataSource={
              globalStore.state.pendingTransactions.transactions
              // Sort the previous transactions by newest first
                .sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime())
            }
            renderItem={(item) => (
              <List.Item>
                <TransactionCard transaction={item} isPending />
              </List.Item>
            )}
          />
        )
      )
    )

    return [
      {
        key: 'history',
        label: 'History',
        children: generateTransactionCardList()
      },
      {
        key: 'pending',
        label: 'Pending',
        children: generatePendingTransactionCardList()
      }
    ]
  }, [
    globalStore.state.previousTransactions.transactions,
    globalStore.state.pendingTransactions.transactions,
    isAnonymous
  ])

  const handleSignIn = () => {
    navigate(EnumRoute.SignIn)
  }

  const handleSignOut = async () => {
    await signOut()
    openMessage({
      text1: 'You have been signed out!',
      text2: 'Hope to see you soon!',
      type: EnumMessageType.INFO,
      duration: 3
    })
  }

  const onEditInfoBtnClick = () => {
    setOpenEditInfoModal(true)
  }

  const closeEditInfoModal = () => {
    setOpenEditInfoModal(false)
  }

  const onVerifyIdentityBtnClick = async () => {
    setIsVerifyingIdentity(true)
    try {
      const onboardRes: any = await (await authApiAxiosWithRefresh()).post('kyc/onboard', {
        context: EnumKycContext.UserSetting
      })
      window.location.href = onboardRes.data.url
      setIsVerifyingIdentity(false)
    } catch (error: any) {
      setIsVerifyingIdentity(false)
      openMessage({
        text1: 'There was an error while verifying your identity.',
        text2: 'Please try again soon.',
        type: EnumMessageType.ERROR
      })
    }
  }

  const renderRightItems = () => (
    <SettingOutlined onClick={() => navigate(EnumRoute.Settings)} />
  )

  const items: MenuProps['items'] = useMemo(() => {
    const menuItems = [
      {
        key: '1',
        label: (
          <div
            className="account-page__dropdown-menu-item"
            onClick={onEditInfoBtnClick}
          >
            <EditOutlined className="account-page__dropdown-menu-item-icon" />
            <span className="account-page__dropdown-menu-item-text">Edit Info</span>
          </div>
        )
      }
    ]
    if (userProfile?.kyc?.status !== KycStatus.COMPLETED) {
      menuItems.push({
        key: '2',
        label: (
          <div
            className="account-page__dropdown-menu-item"
            onClick={onVerifyIdentityBtnClick}
          >
            <CreditCardOutlined className="account-page__dropdown-menu-item-icon" />
            <span className="account-page__dropdown-menu-item-text">
              Verify Identity
            </span>
          </div>
        )
      })
    } else {
      menuItems.push({
        key: '2',
        label: (
          <div
            className="account-page__dropdown-menu-item"
            onClick={() => setShowKycDetail(true)}
          >
            <CreditCardOutlined className="account-page__dropdown-menu-item-icon" />
            <span className="account-page__dropdown-menu-item-text">
              View Connected Account
            </span>
          </div>
        )
      })
    }
    return menuItems
  }, [userProfile])

  const getLoggedInContent = () => (
    <>
      {isVerifyingIdentity && (
        <LoadingModal title="Verifying Identity..." />
      )}
      <h6 className="account-page__user-info">
        {email ?? 'Logged In User'}
      </h6>
      <div>
        <Button
          type="primary"
          color="primary"
          className="account-page__btn"
          icon={<UploadOutlined rotate={90} />}
          onClick={handleSignOut}
        >
          Log Out
        </Button>
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement="bottomRight"
        >
          <Button
            type="primary"
            color="default"
            className="account-page__btn account-page__profile-btn"
            icon={<DownOutlined />}
          >
            Profile
          </Button>
        </Dropdown>
      </div>
    </>
  )

  const getLoggedOutContent = () => (
    <>
      <h6 className="account-page__user-info">
        Anonymous
      </h6>
      <Button
        type="primary"
        className="account-page__btn"
        icon={<DownloadOutlined rotate={-90} />}
        onClick={handleSignIn}
      >
        Sign In
      </Button>
    </>
  )

  return (
    <HeaderLayout
      title="Account"
      hasGoBack={false}
      subtitle={subtitle}
      rightItems={renderRightItems}
    >
      {
        !isAuthenLoaded ? (
          <LoadingModal title="Loading..." />
        ) : (

          <Row className="account-page">
            <Modal
              className="kyc-details-modal"
              open={showKycDetail}
              centered
              width={400}
              closable
              footer={null}
              onCancel={() => setShowKycDetail(false)}
            >
              <div className="kyc-details-modal__title">Connected Account:</div>
              <Spacer space={16} />
              <table>
                <tbody>
                  <tr>
                    <td><b>Business Type:</b></td>
                    <td>{userProfile?.kyc?.businessType}</td>
                  </tr>
                  <tr>
                    <td><b>Bank Account No.:</b></td>
                    <td>
                      ********
                      {userProfile?.kyc?.bankAccNo}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Modal>
            <Col className="account-page__content" span={20} offset={2}>
              <CustomAvatar
                className="account-page__avatar"
                email={email || ''}
                token={refreshToken || ''}
                size={120}
                border
              />
              {
                currentUser?.isAnonymous === false ? (
                  getLoggedInContent()
                ) : (
                  getLoggedOutContent()
                )
              }
              <Tabs className="transactions-tabs" centered items={tabItems} />
            </Col>
          </Row>
        )
      }
      <EditInfoModal open={openEditInfoModal} onClose={closeEditInfoModal} />
    </HeaderLayout>
  )
}
