import React, {
  useContext
} from 'react'
import {
  Col, Form, Row, Typography
} from 'antd'
import classNames from 'classnames'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'
import { Switch } from 'src/components/Switch'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'

import './styles.scss'

const { Title, Text } = Typography

type CheckboxActionValue = { [key: string]: string[] }
type CheckboxActionsMapping = {
  [key: string]: CheckboxActionValue
}

export default function NewTransactionShippingInfo() {
  const { navigateToStep } = useNewTransaction()
  const globalStore = useContext(store)
  const [form] = Form.useForm()

  const {
    shipment
  } = globalStore.state.createTransaction
  const {
    enabled: shipmentEnabled
  } = shipment

  const handleSubmit = async () => {
    navigateToStep({ step: EnumNewTransactionParams.FeeInfo })
  }

  const onUseOurShippingServiceSwitchChange = () => {
    globalStore.dispatch({
      type: 'UPDATE_SHIPMENT_ENABLED',
      enabled: !shipmentEnabled
    })
  }

  return (
    <Row className={classNames('shipping-info')}>
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <Title className="shipping-info__title" level={4}>
          Shipping Service
        </Title>
        <Spacer space={12} />
        <Form
          className="shipping-info__form kuaay-form"
          onFinish={handleSubmit}
          form={form}
        >
          <div className="shipping-info__form-switch-field" onClick={onUseOurShippingServiceSwitchChange}>
            <Switch
              className="shipping-info__form-switch-btn"
              checked={shipmentEnabled}
              onChange={onUseOurShippingServiceSwitchChange}
            />
            <Text className="shipping-info__form-switch-label">
              Use our shipping service
            </Text>
          </div>
        </Form>
        <Spacer className="flex-grow-1" space={24} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="primary"
          onClick={() => form.submit()}
        >
          Continue
        </Button>
      </Col>
    </Row>
  )
}
