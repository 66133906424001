import React from 'react'

import { AppActions } from 'src/store/rootActions'
import { EnumTransactionAction } from 'src/store/types'
import { ShipmentInfo } from 'src/types/shipments'
import { TransactionInfo, TransactionInfoRaw } from 'src/types/transactions'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { shipmentRawToShipmentInfo } from 'src/utils/shipment'
import { checkVerifyTimeout } from 'src/utils/transaction'

export const applyMiddleware = (dispatch: React.Dispatch<AppActions>) => (action: AppActions) => {
  dispatch(action)

  switch (action.type) {
  case EnumTransactionAction.FetchPreviousTransactions:
    authApiAxiosWithRefresh()
      .then((axiosInst) => {
        axiosInst
          .post('/transaction/retrieve')
          .then((res) => {
            const transactions = res?.data
            dispatch({
              type: 'UPDATE_PREVIOUS_TRANSACTIONS',
              transactions: !!transactions
                ? ((transactions as TransactionInfoRaw[]).map((t) => {
                  const newCreationDate = !!t.creationDate
                    ? new Date(t.creationDate)
                    : new Date()
                  delete t.creationDate
                  return {
                    ...t,
                    creationDate: newCreationDate
                  }
                }) as TransactionInfo[])
                : []
            })
          })
          .catch((error) => console.error(error))
      })
      .catch((error) => console.error(error))
    break
  case EnumTransactionAction.FetchPendingTransactions:
    authApiAxiosWithRefresh()
      .then((axiosInst) => {
        axiosInst
          .post('/transaction/retrieve/pending')
          .then((res) => dispatch({
            type: 'UPDATE_PENDING_TRANSACTIONS',
            transactions: !!res?.data
              ? ((res.data as TransactionInfoRaw[]).map((t) => {
                const newCreationDate = !!t.creationDate
                  ? new Date(t.creationDate)
                  : new Date()
                delete t.creationDate
                return {
                  ...t,
                  creationDate: newCreationDate
                }
              }) as TransactionInfo[])
              : []
          }))
          .catch((error) => console.error(error))
      })
      .catch((error) => console.error(error))
    break
  case EnumTransactionAction.FetchShipments:
    authApiAxiosWithRefresh()
      .then((axiosInst) => {
        axiosInst
          .post('/shipment/retrieve')
          .then((res) => dispatch({
            type: 'UPDATE_SHIPMENTS',
            shipments: !!res?.data
              ? ((res.data as ShipmentInfo[]).map((t) => shipmentRawToShipmentInfo(t)) as ShipmentInfo[])
                .sort((a, b) => b.creationDate.getTime() - a.creationDate.getTime())
              : []
          }))
          .catch((error) => console.error(error))
      })
      .catch((error) => console.error(error))
    break
  case EnumTransactionAction.FetchUserPhone:
    authApiAxiosWithRefresh()
      .then((axiosInst) => {
        axiosInst
          .get('/user/phone')
          .then((res) => dispatch({
            type: 'UPDATE_PHONE',
            phone: res.data
          }))
          .catch((error) => console.error(error))
      })
      .catch((error) => console.error(error))
    break
  case EnumTransactionAction.FetchUserInfo:
    authApiAxiosWithRefresh()
      .then((axiosInst) => {
        axiosInst
          .get('/user/profile')
          .then((res) => {
            const { phone, fullName } = res.data
            dispatch({
              type: 'UPDATE_PHONE',
              phone
            })
            dispatch({
              type: 'UPDATE_NAME',
              name: fullName
            })
          })
          .catch((error) => console.error(error))
      })
      .catch((error) => console.error(error))
    break
  default:
    break
  }
}
