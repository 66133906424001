import { UserProfile } from '../types/users'

import { authApiAxiosWithRefresh } from './customAxios'

export const fetchKycStatus = async (email: string | undefined) => {
  try {
    const kycStatusRes = await (await authApiAxiosWithRefresh()).get(`kyc/status?email=${email}`)
    return kycStatusRes.data
  } catch (error: any) {
    console.error('Failed to retrieve KYC status!', error)
    return undefined
  }
}

export const fetchUserProfile = async () => {
  try {
    const res = await (await authApiAxiosWithRefresh()).get('user/profile')
    return res.data as UserProfile
  } catch (error: any) {
    console.error('Failed to retrieve KYC status!', error)
    return undefined
  }
}
