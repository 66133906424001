import { CloseOutlined } from '@ant-design/icons'
import { message } from 'antd'

export enum EnumMessageType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success'
}

export interface MessageProps {
  type: EnumMessageType
  text1: string
  text2?: string
  duration?: number
}

export function useMessage() {
  const openMessage = ({
    duration, text1, text2, type
  }: MessageProps) => {
    const computedDuration = duration ?? 6
    message.open({
      duration: computedDuration,
      type,
      content: (
        <div className="ant-message-notice__content">
          <div className="ant-message-notice__text-wrapper">
            <div className="ant-message-notice__first-line">
              {text1}
            </div>
            {
              text2 && (
                <div className="ant-message-notice__second-line">{text2}</div>
              )
            }
          </div>
          <CloseOutlined onClick={() => message.destroy()} />
        </div>
      )
    })
  }

  return {
    openMessage
  }
}
