import { User } from 'firebase/auth'

import { AppActions } from 'src/store/rootActions'

type Token = string | null | undefined;
type AuthState = {
  isLoading: boolean;
  isSignout: boolean;
  user: User | null;
  userToken: Token;
  userRefreshToken: Token;
  isAnonymous: boolean;
};

const initialAuthState: AuthState = {
  isLoading: true,
  isSignout: false,
  user: null,
  userToken: null,
  userRefreshToken: null,
  isAnonymous: true
}

const authReducer = (prevState: AuthState, action: AppActions) => {
  switch (action.type) {
  case 'RESTORE_TOKEN':
    return {
      ...prevState,
      userToken: action.token,
      isLoading: false
    }
  case 'TYRING_LOADING':
    return {
      ...prevState,
      isLoading: true
    }
  case 'DONE_TYRING_LOADING':
    return {
      ...prevState,
      isLoading: false
    }
  case 'UPDATE_CURRENT_USER':
    return {
      ...prevState,
      user: { ...action.user }
    }
  case 'UPDATE_TOKENS':
    return {
      ...prevState,
      userToken: action.token,
      userRefreshToken: action.refreshToken || prevState.userRefreshToken
    }
  case 'SIGN_IN':
    return {
      ...prevState,
      isSignout: false,
      userToken: action.token,
      user: { ...action.user },
      userRefreshToken: action.refreshToken,
      isAnonymous: action.isAnonymous
    }
  case 'SIGN_OUT':
    // NOTE: Resetting the local storage of user and transaction information is handled elsewhere
    return {
      ...prevState,
      isSignout: true,
      user: action.user ? { ...action.user } : null,
      userToken: action.anonToken,
      userRefreshToken: action.anonRefreshToken,
      isAnonymous: true
    }
  default:
    return prevState
  }
}

export type {
  Token, AuthState
}

export {
  authReducer, initialAuthState
}
