import { useContext, useMemo } from 'react'

import { store } from 'src/store/store'
import { EnumUserAction } from 'src/store/types'
import { EnumTheme } from 'src/utils/types'

function useTheme() {
  const globalStore = useContext(store)
  const theme = useMemo(
    () => globalStore.state.user.settings.theme,
    [globalStore.state.user.settings.theme]
  )

  const changeTheme = () => {
    globalStore.dispatch({
      type: EnumUserAction.UpdateTheme,
      theme:
        globalStore.state.user.settings.theme === EnumTheme.Dark
        // globalStore.state.user.settings.theme === EnumTheme.Dark ? EnumTheme.Light : EnumTheme.Dark

    })
  }
  return { theme, changeTheme }
}

export { useTheme }
