import { USE_PROD_STRIPE } from './constants'
import { authApiAxiosWithRefresh } from './customAxios'

export const fetchStripePublicKey = async (retry: boolean = false): Promise<undefined | string> => {
  try {
    const publicKeyResponse = await (
      await authApiAxiosWithRefresh()
    ).post('/payment/publishable-key', {
      prod: USE_PROD_STRIPE
    })
    return publicKeyResponse.data
  } catch (err) {
    if (retry) {
      // eslint-disable-next-line no-console
      console.error('Failed to stripe public key! RETRYING!', err)
      return fetchStripePublicKey(false)
    }
    // eslint-disable-next-line no-console
    console.error('Failed to stripe public key!', err)
    return undefined
  }
}
