import React, { useContext, useMemo, useState } from 'react'
import {
  Col, Form, Row, Typography
} from 'antd'

import { Button } from 'src/components/Button'
import { Input } from 'src/components/Input'
import { LoadingModal } from 'src/components/Modal'
import { Spacer } from 'src/components/Spacer'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { EMAIL_REGEX } from 'src/utils/constants'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'

import './styles.scss'

const { Text } = Typography

interface NewTransactionNextUserProps {
  userType: EnumUserType
}

export default function NewTransactionNextUser({
  userType
}: NewTransactionNextUserProps) {
  const { navigateToStep } = useNewTransaction()
  const { openMessage } = useMessage()
  const [form] = Form.useForm()
  const globalStore = useContext(store)
  const userRole = transactionUserInfo(userType, globalStore).role
  const [showSharingModal, setShowSharingModal] = useState(false)

  const {
    secondaryUserPhone,
    secondaryUser,
    documentEnabled
  } = globalStore.state.createTransaction

  const { isAnonymous } = useAuthen()

  const renderDescription = () => (
    <Text>
      Now we need information from the
      {' '}
      {userRole === EnumUserRole.Buyer
        ? 'seller'
        : 'buyer'}
      . Either enter their information below (only available if logged
      in), or press “Use this device” to continue on this device. You
      will need their Driver’s License to continue.
    </Text>
  )

  const handleUseThisDeviceClick = () => {
    const userTypeToNavigate = userType === EnumUserType.Main
      ? EnumUserType.Secondary
      : EnumUserType.Main
    navigateToStep({ step: EnumNewTransactionParams.NeededInfo, userType: userTypeToNavigate })
  }

  const handleShare = async () => {
    if (
      !!secondaryUser.email && secondaryUser.email.length > 0
    ) {
      setShowSharingModal(true)
      await (
        await authApiAxiosWithRefresh()
      )
        .post('/transaction/share', {
          transactionInfo: globalStore.state.createTransaction,
          receiverEmail:
            globalStore.state.createTransaction.secondaryUser.email
        })
        .then((e) => {
          setShowSharingModal(false)
          // We succeeded so send the user to the "share confirmation" screen
          navigateToStep({
            step:
              EnumNewTransactionParams.ShareConfirmation,
            userType: userType === EnumUserType.Main ? EnumUserType.Secondary : EnumUserType.Main,
            shareUrl: encodeURIComponent(e.data.shareUrl)
          })
        })
        .catch((e) => {
          setShowSharingModal(false)
          openMessage({
            text1: 'There was an error while sharing. Please try again soon.',
            type: EnumMessageType.ERROR
          })
        })
    }
  }

  const renderShareButtonText = () => (
    `Share Link${isAnonymous
      ? ' (Must be logged in)'
      : ' (Beta)'
    }`
  )

  const shareLinkButtonDisabled = useMemo(
    () => isAnonymous || !secondaryUser.email?.length || showSharingModal,
    [isAnonymous, secondaryUser.email, showSharingModal]
  )

  const emailCustomValidationRule = {
    validator(_: any, value: any) {
      if (EMAIL_REGEX.test(value)) {
        globalStore.dispatch({
          type: 'UPDATE_SECONDARY_USER_EMAIL',
          email: value
        })
        return Promise.resolve()
      }
      globalStore.dispatch({
        type: 'UPDATE_SECONDARY_USER_EMAIL',
        email: ''
      })
      return Promise.reject(new Error('Email is incorrect'))
    }
  }

  return (
    <Row className="new-transaction-next-user">
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <Spacer space={12} />
        <div className="new-transaction-next-user__text-wrapper">
          {renderDescription()}
        </div>
        <Spacer space={24} />
        <Form
          className="main-vehicle-info__form"
          form={form}
        >
          <Form.Item
            name="secondaryUserPhone"
            initialValue={secondaryUserPhone}
            className="new-transaction-next-user__phone-form-item"
          >
            <Input
              disabled
              placeholder="Phone Number (Coming soon!)"
              onChange={(e) => {
                globalStore.dispatch({
                  type: 'UPDATE_SECONDARY_USER_PHONE',
                  phone: e.target.value
                })
              }}
            />
          </Form.Item>
          <Text className="new-transaction-next-user__text-or">
            OR
          </Text>
          <Spacer space={16} />
          <Form.Item
            name="email"
            initialValue={secondaryUser.email}
            rules={[emailCustomValidationRule]}
          >
            <Input
              placeholder="Email Address"
              autoCapitalize="none"
              inputMode="email"
            />
          </Form.Item>
        </Form>
        <Spacer className="flex-grow-1" space={16} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="secondary"
          onClick={handleShare}
          disabled={shareLinkButtonDisabled}
        >
          {renderShareButtonText()}
        </Button>

        <Button
          className="transaction-form__btn new-transaction-next-user__use-this-device-btn"
          color="primary"
          type="primary"
          onClick={handleUseThisDeviceClick}
        >
          Use this device
        </Button>
        { showSharingModal && <LoadingModal title="Sharing..." /> }
      </Col>
    </Row>
  )
}
